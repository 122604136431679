import { cloneDeep } from 'lodash';
import { updateObject } from '../utility';
import { toast } from 'react-toastify';

const initialState = {
	dispatchedData: JSON.parse(localStorage.getItem('groups')) ?? [],
	selectedGroupId: null,
	isGroupOrdersModalOpen: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ADD_NEW_GROUP': {
			const dispatchedData = state.dispatchedData,
				newDispatchedData = [
					...dispatchedData,
					{
						id: dispatchedData.length + 1,
						orders: [],
						driver: { name: '', id: '' },
					},
				];
			localStorage.setItem('groups', JSON.stringify(newDispatchedData));
			return updateObject(state, { dispatchedData: newDispatchedData });
		}

		case 'SET_DELETE_GROUP': {
			const dispatchedData = state.dispatchedData,
				newDispatchedData = dispatchedData.filter((item) => item.id != action.id);
			newDispatchedData.forEach((el, i) => {
				el.id = i + 1;
			});
			localStorage.setItem('groups', JSON.stringify(newDispatchedData));
			return updateObject(state, { dispatchedData: newDispatchedData });
		}

		case 'SET_SELECTED_GROUP': {
			return updateObject(state, { selectedGroupId: action.id });
		}

		case 'SET_TOGGLE_GROUP_ORDERS_MODAL': {
			return updateObject(state, { isGroupOrdersModalOpen: !state.isGroupOrdersModalOpen });
		}

		case 'SET_ASSIGN_DRIVER': {
			const dispatchedData = state.dispatchedData,
				clonedDispatchedData = cloneDeep(dispatchedData);
			clonedDispatchedData.forEach((item) => {
				if (item.id == state.selectedGroupId) {
					item.driver.name = action.payload.name;
					item.driver.id = action.payload.id;
				}
			});
			localStorage.setItem('groups', JSON.stringify(clonedDispatchedData));
			return updateObject(state, { dispatchedData: clonedDispatchedData });
		}

		case 'SET_SCAN_ORDER_QR_CODE': {
			const dispatchedData = state.dispatchedData,
				clonedDispatchedData = cloneDeep(dispatchedData);
			clonedDispatchedData.forEach((item) => {
				if (item.id == state.selectedGroupId) {
					item.orders.push({ order: action.payload, groupId: state.selectedGroupId });
				}
			});
			toast.success('Order added successfully');
			localStorage.setItem('groups', JSON.stringify(clonedDispatchedData));
			return updateObject(state, {
				dispatchedData: clonedDispatchedData,
			});
		}

		case 'SET_SELECTED_GROUP': {
			return updateObject(state, { selectedGroupId: action.id });
		}

		case 'SET_UPDATED_DISPATCHED_DATA': {
			localStorage.setItem('groups', JSON.stringify(action.payload));
			return updateObject(state, { dispatchedData: action.payload });
		}
		default:
			return state;
	}
};

export default reducer;
