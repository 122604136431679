import axios from 'axios';
import { setBaseUrl } from './SetBaseUrl';
import { getDecodedToken } from '../../store/app/AppSelectors';
import { store } from '../../index';

const base = setBaseUrl();

export const apiLogisticsService = axios.create({
  baseURL: base.logistics,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('LOGISTICS_TOKEN')}`,
  },
});

export const apiOrderService = axios.create({
  baseURL: base.order,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('LOGISTICS_TOKEN')}`,
  },
});

export const apiGatewayService = axios.create({
  baseURL: base.gateway,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('LOGISTICS_TOKEN')}`,
  },
});

export const apiHomeService = axios.create({
  baseURL: base.home,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('LOGISTICS_TOKEN')}`,
  },
});

export const apiUserService = axios.create({
  baseURL: base.user,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('LOGISTICS_TOKEN')}`,
  },
});

export const googleService = axios.create({
  baseURL: base.google,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('GOOGLE_AUTH_TOKEN')}`,
  },
});

export const googleTokenService = axios.create({
  baseURL: base.google_token,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('LOGISTICS_TOKEN')}`,
  },
});

const requestInterceptor = (config) => {
  config.headers['flwuser'] = getDecodedToken({ state: store.getState() }).Id;
  config.headers['opsId'] = localStorage.getItem('opsId');
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('LOGISTICS_TOKEN')}`;
  config.headers['lang'] = 1;
  return config;
};

const googleRequestInterceptor = (config) => {
  config.headers['languageCode'] = 'en-US';
  config.headers['regionCode'] = 13;
  config.headers['sdkType'] = 'JAVASCRIPT';
  config.headers['platform'] = 'WEB';
  return config;
};

const requestInterceptorError = (error) => {
  return Promise.reject(error);
};

const responseInterceptor = (response) => {
  return response;
};

const responseInterceptorError = (error) => {
  const errorResponse = error.response;

  return Promise.reject(error);
};

//request
apiLogisticsService.interceptors.request.use(requestInterceptor, requestInterceptorError);

apiOrderService.interceptors.request.use(requestInterceptor, requestInterceptorError);

apiGatewayService.interceptors.request.use(requestInterceptor, requestInterceptorError);

apiHomeService.interceptors.request.use(requestInterceptor, requestInterceptorError);

apiUserService.interceptors.request.use(requestInterceptor, requestInterceptorError);

googleService.interceptors.request.use(googleRequestInterceptor, requestInterceptorError);

googleTokenService.interceptors.request.use(requestInterceptor, requestInterceptorError);

//response
apiLogisticsService.interceptors.response.use(responseInterceptor, responseInterceptorError);

apiOrderService.interceptors.response.use(responseInterceptor, responseInterceptorError);

apiGatewayService.interceptors.response.use(responseInterceptor, responseInterceptorError);

apiHomeService.interceptors.response.use(responseInterceptor, responseInterceptorError);

apiUserService.interceptors.response.use(responseInterceptor, responseInterceptorError);

googleService.interceptors.request.use(responseInterceptor, requestInterceptorError);

googleTokenService.interceptors.request.use(responseInterceptor, requestInterceptorError);
