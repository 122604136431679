import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
//material ui
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
//components
import OrderDetailsInfo from "./OrderDetailsInfo";
import OrderCartDetails from "./OrderCartDetails";

const OrderDetailsContent = ({ orderId }) => {
  const dispatch = useDispatch();
  const handleOpenInHub = () => {
    window.open(`https://hub.floward.io/order/${orderId}`, "_blank");
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: "SET_ORDER_DETAILS_INFO",
        payload: "",
      });
      dispatch({
        type: "SET_ORDER_CART_DETAILS",
        payload: [],
      });
    };
  }, []);

  return (
    <div>
      <OrderDetailsInfo />
      <OrderCartDetails />
      <ListItem className='show-hub-btn'>
        <Button
          onClick={handleOpenInHub}
          size='medium'
          variant='contained'
          color='primary'>
          Open in HUB
        </Button>
      </ListItem>
    </div>
  );
};

export default OrderDetailsContent;
