import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
//components
import MapComponent from '../components/home/googleMap/MapComponent';
import TripsComponent from '../components/home/trips/TripsComponent';
import DriversComponent from '../components/home/drivers/DriversComponent';
//LMFS components
import LMFSMapComponent from '../components/home/googleMap/LMFSMapComponent';
//selectors
import { getOpsCenterId } from '../store/app/AppSelectors';

const HomePage = () => {
  const opsId = useSelector((state) => getOpsCenterId({ state }));
  return (
    <Fragment>
      {opsId == 13 ? <LMFSMapComponent /> : <MapComponent />}
      <TripsComponent />
      <DriversComponent />
    </Fragment>
  );
};

export default HomePage;
