import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
//material ui
import { makeStyles, fade } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
//actions
import {
  setDeliveryVehicleId,
  setLMFSToggleDriverTasks,
} from '../../../../store/googleLMFS/GoogleLMFSActions';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        width: '100%',
      },
    },
  },
}));

const LMFSTripsHeader = () => {
  const [searchValue, setSearchValue] = useState(''),
    dispatch = useDispatch(),
    classes = useStyles();

  const handleSearchTrip = (e) => {
    if (e.key === 'Enter') {
      dispatch(setLMFSToggleDriverTasks());
      dispatch(setDeliveryVehicleId(e.target.value));
      setSearchValue('');
    }
  };

  return (
    <Fragment>
      <div className={`${classes.toolbar} drivers-list-title`}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>

          <InputBase
            placeholder="Search by Driver ID"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'Search' }}
            onKeyPress={handleSearchTrip}
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            type="number"
          />
        </div>
      </div>
      <Divider />
    </Fragment>
  );
};

export default LMFSTripsHeader;
