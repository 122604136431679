export const getIsShowDriverTrip = ({ state }) =>
  state.drivers.isShowDriverTrip;

export const getReadyDriversList = ({ state }) =>
  state.drivers.readyDriversList;

export const getReadyDriversLength = ({ state }) =>
  state.drivers.readyDriversLength;

export const getClonedDriversList = ({ state }) =>
  state.drivers.clonedDriversList;

export const getDriverInfoById = ({ state }) => state.drivers.driverInfoById;

export const getDriverOrdersList = ({ state }) =>
  state.drivers.driverOrdersList;

export const getSelectedDriver = ({ state }) => state.drivers.selectedDriver;

export const getSelectedDriverByMap = ({ state }) =>
  state.drivers.selectedDriverByMap;

export const getSelectedOrderByMap = ({ state }) =>
  state.drivers.selectedOrderByMap;

export const getDriverTabValue = ({ state }) => state.drivers.driverTabValue;

export const getIsOrderDetailsModalOpen = ({ state }) =>
  state.drivers.isOrderDetailsModalOpen;

export const getSelectedOrderId = ({ state }) => state.drivers.selectedOrderId;

export const getOrderDetailsInfo = ({ state }) =>
  state.drivers.orderDetailsInfo;

export const getOrderCartDetails = ({ state }) =>
  state.drivers.orderCartDetails;

export const getIsShowOnMap = ({ state }) => state.drivers.isShowOnMap;
