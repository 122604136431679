import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
//components
import SectionLoader from '../../../shared/SectionLoader';
import OrderDetailsContent from '../../../modals/orderDetailsModal/OrderDetailsContent';
import Modal from '../../../shared/Modal';
//constants
import { orderStatusIcon, convertToDateAndTime } from '../../../../constants/Helpers';
//icons
import PersonIcon from '@material-ui/icons/Person';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import ErrorIcon from '@material-ui/icons/Error';
//actions
import {
	setDriverOrdersList,
	setToggleOrderDetailsModal,
} from '../../../../store/drivers/DriversActions';
//selectors
import {
	getDriverOrdersList,
	getIsOrderDetailsModalOpen,
} from '../../../../store/drivers/DriversSelectors';

const DriverTripComponent = ({ selectedDriver }) => {
	const dispatch = useDispatch(),
		driverOrdersList = useSelector((state) => getDriverOrdersList({ state })),
		isOrderDetailsModalOpen = useSelector((state) => getIsOrderDetailsModalOpen({ state })),
		[isLoaderOpen, setIsLoaderOpen] = useState(false),
		[orderId, setOrderId] = useState('');

	const fetchDriversOrders = async () => {
		setIsLoaderOpen(true);
		await dispatch(setDriverOrdersList(selectedDriver.id));
		setIsLoaderOpen(false);
	};

	useEffect(() => {
		fetchDriversOrders(selectedDriver.id);
	}, []);

	const handleOpenOrderModal = (orderId) => {
		dispatch(setToggleOrderDetailsModal(orderId));
		setOrderId(orderId);
	};

	const handleCloseModal = () => {
		dispatch(setToggleOrderDetailsModal(''));
	};

	return (
		<div className="driver-trip-wrapper">
			{isOrderDetailsModalOpen && (
				<Modal
					isOpen={isOrderDetailsModalOpen}
					handleSubmit={handleCloseModal}
					closeModal={handleCloseModal}
					content={<OrderDetailsContent orderId={orderId} />}
					title={`Order# ${orderId}`}
					submitBtnTitle="Close"
				/>
			)}
			<List component="nav" aria-label="main mailbox folders">
				<ListItem>
					<ListItemIcon>
						<PersonIcon />
					</ListItemIcon>
					<ListItemText primary={selectedDriver.name} />
				</ListItem>
				<Divider />
				<ListItem>
					<ListItemIcon>
						<PhoneIphoneIcon />
					</ListItemIcon>
					<ListItemText primary={selectedDriver.phone} />
				</ListItem>
				<Divider />
				<ListItem>
					<ListItemIcon>
						<ShoppingCartIcon />
					</ListItemIcon>
					<ListItemText primary={`${selectedDriver.totalOrders} Orders`} />
				</ListItem>
				<Divider />
				<ListItem>
					<ListItemIcon>
						<WatchLaterIcon />
					</ListItemIcon>
					<ListItemText primary={`Last seen: ${convertToDateAndTime(selectedDriver.lastSeen)}`} />
				</ListItem>
				{/* <ListItem>
          <Button
            onClick={handleShowOrdersList}
            size='small'
            variant='contained'
            color='primary'>
            {isShowTripOrders ? "Hide orders" : "Show orders"}
          </Button>
        </ListItem> */}
			</List>

			{isLoaderOpen ? (
				<SectionLoader />
			) : (
				<List
					component="nav"
					aria-label="secondary mailbox folders"
					className="driver-trip-content"
				>
					{driverOrdersList.length > 0 ? (
						driverOrdersList.map((item, i) => (
							<Fragment key={i}>
								<ListItem
									onClick={() => handleOpenOrderModal(item.orderId)}
									button
									className="list-item"
									alignItems="flex-start"
								>
									<ListItemText
										primary={
											<div className="order-wrapper">
												{item.priority === 1 ? <ErrorIcon className="high-priority" /> : ''}
												Order# {item.orderId}
											</div>
										}
										secondary={
											<Fragment>
												<Typography component="span" variant="body2" color="textPrimary">
													<div className="list-content-style">
														<div style={{ width: '85%' }}>
															<p>Time Slot: {item.timeSlotName}</p>
															<p style={{ marginTop: 5 }}>{`ETA: ${
																item.eta ? convertToDateAndTime(item.eta) : ''
															}`}</p>
															{item.address?.area && <p>Area: {item.address?.area}</p>}
															{item.address && item.address.postalCode && (
																<p>Postal Code: {item.address?.postalCode}</p>
															)}
														</div>
														<div style={{ width: '15%' }} className="orders-counter">
															<img style={{ width: 40 }} src={orderStatusIcon[item.status]} />
														</div>
													</div>
												</Typography>
											</Fragment>
										}
									/>
								</ListItem>
								<Divider variant="inset" component="li" />
							</Fragment>
						))
					) : (
						<p className="no-orders-title">No Orders</p>
					)}
				</List>
			)}
		</div>
	);
};

export default DriverTripComponent;
