import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { toast } from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
//constants
import { handleRealoadPage } from '../../constants/Helpers';
//modals
import Modal from '../shared/Modal';
import AssignOrdersModalContent from '../modals/assignOrdersModal/AssignOrdersModalContent';
import SortOrdersModalContent from '../modals/sortOrdersModal/SortOrdersModalContent';
import OpsModalContent from '../modals/opsModal/OpsModalContent';
//icons
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import ReplayIcon from '@material-ui/icons/Replay';
//actions
import {
  setToggleDarkLightMode,
  setToggleLeftSideBar,
  setToggleRightSideBar,
  setToggleAssignOrdersModalOpen,
  setToggleOpsModalOpen,
  setIncreaseDefaultZoom,
  setDecreaseDefaultZoom,
  setResetDefaultZoom,
} from '../../store/app/AppActions';
import {
  setToggleSwitchProposedList,
  setConfirmOptimization,
} from '../../store/assignDriversOrders/AssignDriversOrdersActions';
import {
  setSelectedDriverByMap,
  setSelectedDriver,
  setHideDriverTripDetails,
} from '../../store/drivers/DriversActions';
import { setDragCenterCoords } from '../../store/googleMap/GoogleMapActions';
//selectors
import {
  getIsDarkMode,
  getIsAssignOrdersModalOpen,
  getDecodedToken,
  getIsOpsModalOpen,
  getIsMobileSize,
  getIsDesktopSize,
  getOpsCenterId,
  getOpsCenterInfo,
} from '../../store/app/AppSelectors';
import {
  getIsSwitchProposedList,
  getSelectedReadyDrivers,
  getSelectedReadyOrders,
} from '../../store/assignDriversOrders/AssignDriverOrdersSelectors';
import { getSelectedDriver } from '../../store/drivers/DriversSelectors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    zIndex: 2001,
  },
  title: {
    flexGrow: window.screen.width < 500 ? 0 : 1,
    fontFamily: 'sans-serif',
  },
  button: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(2),
    padding: '4px 10px 4px 4px',
    fontSize: 11,
  },
}));

const Navbar = ({ location, history }) => {
  const classes = useStyles(),
    isDarkMode = useSelector((state) => getIsDarkMode({ state })),
    isAssignOrdersModalOpen = useSelector((state) => getIsAssignOrdersModalOpen({ state })),
    isSwitchProposedList = useSelector((state) => getIsSwitchProposedList({ state })),
    selectedReadyDrivers = useSelector((state) => getSelectedReadyDrivers({ state })),
    selectedReadyOrders = useSelector((state) => getSelectedReadyOrders({ state })),
    isOpsModalOpen = useSelector((state) => getIsOpsModalOpen({ state })),
    selectedDriver = useSelector((state) => getSelectedDriver({ state })),
    decodedToken = useSelector((state) => getDecodedToken({ state })),
    opsId = useSelector((state) => getOpsCenterId({ state })),
    opsCenterInfo = useSelector((state) => getOpsCenterInfo({ state })),
    isMobile = useSelector((state) => getIsMobileSize({ state })),
    isDesktop = useSelector((state) => getIsDesktopSize({ state })),
    [anchorEl, setAnchorEl] = useState(null),
    [hideEle, setHideEle] = useState(false),
    dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname == '/dispatch') {
      setHideEle(true);
    }
  });

  const handleOptimizeOrders = () => {
    if (isSwitchProposedList) {
      //confirm optimization api
      dispatch(setConfirmOptimization());
    } else {
      if (selectedReadyDrivers.length > selectedReadyOrders.length) {
        toast.error("Selected orders can't be less than selected drivers!");
      } else if (selectedReadyDrivers.length === 0 || selectedReadyOrders.length === 0) {
        toast.error('Please select at least one driver and one order');
      } else {
        //optimize api
        dispatch(setToggleSwitchProposedList());
      }
    }
  };

  const handleCloseModal = () => {
    if (isSwitchProposedList) {
      dispatch(setToggleSwitchProposedList());
      dispatch(setToggleAssignOrdersModalOpen());
    } else {
      dispatch(setToggleAssignOrdersModalOpen());
    }
  };

  const handleResetZoom = () => {
    dispatch(setResetDefaultZoom());
    dispatch(setHideDriverTripDetails());
    dispatch(setSelectedDriverByMap(''));
    dispatch(setSelectedDriver(''));
    dispatch(setDragCenterCoords(''));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      {isAssignOrdersModalOpen && (
        <Modal
          isOpen={isAssignOrdersModalOpen}
          handleSubmit={handleOptimizeOrders}
          closeModal={handleCloseModal}
          content={isSwitchProposedList ? <SortOrdersModalContent /> : <AssignOrdersModalContent />}
          title={isSwitchProposedList ? 'Confirm Optimization' : 'Optimize Orders'}
          submitBtnTitle={isSwitchProposedList ? 'Confirm' : 'Optimize'}
        />
      )}

      {isOpsModalOpen && (
        <Modal
          isOpen={isOpsModalOpen}
          handleSubmit={() => dispatch(setToggleOpsModalOpen())}
          closeModal={() => dispatch(setToggleOpsModalOpen())}
          content={<OpsModalContent />}
          title="Change Ops Center"
          submitBtnTitle="Close"
        />
      )}
      <AppBar style={{ backgroundColor: isDarkMode ? '#333' : '#1876D1' }} position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => dispatch(setToggleLeftSideBar())}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.title}>
            <span style={{ cursor: 'pointer' }} onClick={() => (window.location.href = '/')}>
              Fleet
            </span>
            ,{' '}
            <span style={{ color: isDarkMode ? 'crimson' : 'white' }} className="user-name-style">
              Hi {decodedToken.Name}
            </span>
            {!hideEle && (
              <div style={{ display: isDesktop ? '' : 'none' }} className="map-zoom-wrapper">
                <ZoomInIcon
                  style={{ visibility: selectedDriver ? 'hidden' : '' }}
                  onClick={() => dispatch(setIncreaseDefaultZoom())}
                />
                <Button
                  variant="contained"
                  className={classes.button}
                  color="default"
                  onClick={() => handleResetZoom()}
                >
                  Reset
                </Button>
                <ZoomOutIcon
                  style={{ visibility: selectedDriver ? 'hidden' : '' }}
                  onClick={() => dispatch(setDecreaseDefaultZoom())}
                />
              </div>
            )}
          </div>
          <ReplayIcon onClick={handleRealoadPage} className="reload-icon" />
          <div className="header-ops-wrapper">
            <img
              onClick={() => dispatch(setToggleOpsModalOpen())}
              className="header-ops-img"
              src={opsCenterInfo?.IconFullPath}
            />
            <span>{opsCenterInfo?.NickName}</span>
          </div>
          {opsId != 13 && (
            <Button
              aria-controls="simple-menu"
              variant="contained"
              color="default"
              aria-haspopup="true"
              startIcon={<AddIcon className="optimize-icon" />}
              onClick={handleClick}
              size="small"
              style={{
                margin: '0 8px',
                backgroundColor: '#22BB33',
                color: 'white',
                fontWeight: 'normal',
              }}
            >
              Dispatch
            </Button>
          )}

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem
              onClick={() => {
                dispatch(setToggleAssignOrdersModalOpen());
                handleCloseMenu();
              }}
            >
              Auto Dispatch
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push('/dispatch');
                handleCloseMenu();
              }}
            >
              Manual Dispatch
            </MenuItem>
          </Menu>
          <FormGroup style={{ display: isMobile ? 'none' : '' }}>
            <FormControlLabel
              control={
                <Switch
                  style={{ zIndex: 2001 }}
                  checked={isDarkMode}
                  onChange={() => dispatch(setToggleDarkLightMode())}
                  aria-label="login switch"
                />
              }
              label={isDarkMode ? <NightsStayIcon /> : <WbSunnyIcon />}
            />
          </FormGroup>
          <IconButton
            edge="end"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => dispatch(setToggleRightSideBar())}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(Navbar);
