import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
//material ui
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
//shared
import SectionLoader from '../../../shared/SectionLoader';
//constants
import { convertToDateAndTime } from '../../../../constants/Helpers';

//actions
import {
  setLMFSToggleDriverTasks,
  setDeliveryVehicleId,
} from '../../../../store/googleLMFS/GoogleLMFSActions';

const LMFSDriversTripsList = ({ tripsList, isLoaderOpen }) => {
  const dispatch = useDispatch();

  const handleSelectedTrip = (driverId) => {
    dispatch(setDeliveryVehicleId(driverId));
    dispatch(setLMFSToggleDriverTasks());
  };

  return (
    <Fragment>
      <List className="trip-list-wrapper">
        {isLoaderOpen ? (
          <SectionLoader />
        ) : (
          <Fragment>
            {tripsList.deliveryVehicles.map((item, i) => (
              <Fragment key={i}>
                <ListItem
                  onClick={() => handleSelectedTrip(item.name.slice(40))}
                  className="list-item"
                  alignItems="flex-start"
                  button
                >
                  <ListItemAvatar style={{ width: '20%' }}>
                    <Avatar
                      style={{ width: 70 }}
                      alt={item.name}
                      src="https://www.clipartmax.com/png/middle/418-4180154_car-icon-fleet.png"
                    />
                    <span className="MuiTypography-body1 driver-name">{item.name.slice(40)}</span>
                  </ListItemAvatar>
                  <ListItemText
                    style={{ width: '80%' }}
                    primary={`Last seen: ${
                      item.lastLocation.updateTime &&
                      convertToDateAndTime(item.lastLocation.updateTime)
                    }`}
                    secondary={
                      <Fragment>
                        <Typography component="span" variant="body2" color="textPrimary">
                          <div className="list-content-style"></div>
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </Fragment>
            ))}
          </Fragment>
        )}
      </List>
    </Fragment>
  );
};

export default LMFSDriversTripsList;
