//api end points
export const endPoints = {
  //optimize
  getReadyOrders: `hub/order/ready`,
  getDoOptimize: `optimizer`,
  getConfirmOptimization: `optimizer/confirm`,
  //drivers
  getDriversList: `drivers`,
  getDriverInfoById: `drivers/{0}`,
  getDriverOrdersList: `drivers/{0}/orders`,
  getOrderDetailsInfo: `order/hub/orders/{0}/details`,
  getOrderCartDetails: `order/hub/cart/{0}/cartdetails`,
  //trips
  getTripsList: `trips`,
  getTripDetailsData: `trips/{0}/orders`,
  getTripListBySearch: `trips/search`,
  //app
  getOpsInfo: `opscenter/{0}`,
  getAllOpsCenters: `hub/ops`,
  //dispatch
  getValidateOrder: `order/scan/{0}`,
  //googleLMFS
  getGoogleFleetToken: `/lmfs/create-token`,
  getGoogleDriversList: `/deliveryVehicles`,
  getGoogleTasksList: `/tasks`,
};
