//api
import AppService from '../../services/AppService';

export const setIsLoggedInTrue = () => ({ type: 'SET_IS_LOGGED_IN_TRUE' });

export const setToggleDarkLightMode = () => ({
  type: 'TOGGLE_DARK_LIGHT_MODE',
});

export const setToggleLeftSideBar = () => ({
  type: 'TOGGLE_LEFT_SIDE_BAR',
});

export const setToggleRightSideBar = () => ({
  type: 'TOGGLE_RIGHT_SIDE_BAR',
});

export const setToggleAssignOrdersModalOpen = () => ({
  type: 'TOGGLE_ASSIGN_ORDERS_MODAL_OPEN',
});

export const setOpsInfo = () => async (dispatch) => {
  const opsId = localStorage.getItem('opsId');
  try {
    const res = await AppService.getOpsCenterInfo(opsId);
    dispatch({
      type: 'SET_OPS_CENTER_INFO',
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setToggleOpsModalOpen = () => ({
  type: 'TOGGLE_OPS_MODAL_OPEN',
});

export const setAllOpsCenters = () => async (dispatch) => {
  try {
    const res = await AppService.getAllOpsCenter({ allops: true });
    dispatch({
      type: 'SET_ALL_OPS_CENTERS',
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setResetDefaultZoom = () => ({
  type: 'SET_RESET_DEFAULT_ZOOM',
});

export const setIncreaseDefaultZoom = () => ({
  type: 'SET_INCREASE_DEFAULT_ZOOM',
});

export const setDecreaseDefaultZoom = () => ({
  type: 'SET_DECREASE_DEFAULT_ZOOM',
});
