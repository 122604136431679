import { toast } from "react-toastify";
//selectors
import { getSelectedOrderId } from "./DriversSelectors";
//api
import DriversService from "../../services/DriversService";

export const setToggleShowDriverTrip = () => ({
  type: "TOGGLE_SHOW_DRIVER_TRIP",
});

export const setHideDriverTripDetails = () => ({
  type: "HIDE_DRIVER_TRIP_DETAILS",
});

export const setDriversList = (status) => async (dispatch) => {
  const params = {
    status,
  };
  try {
    const res = await DriversService.getDriversList(params);
    dispatch({
      type: "SET_READY_DRIVERS_LIST",
      payload: res.data,
    });

    dispatch({
      type: "SET_CLONED_DRIVERS_LIST",
      payload: res.data,
    });
  } catch (err) {
    toast.error("Failed to fetch drivers list");
  }
};

export const setSelectedDriver = (selectedDriver) => ({
  type: "SELECTED_DRIVER_DATA",
  payload: selectedDriver,
});

export const setSelectedDriverByMap = (selectedDriver) => ({
  type: "SELECTED_DRIVER_DATA_BY_MAP",
  payload: selectedDriver,
});

export const setSelectedOrderByMap = (selectedOrder) => ({
  type: "SELECTED_ORDER_DATA_BY_MAP",
  payload: selectedOrder,
});

export const setDriverInfoById = (id) => async (dispatch) => {
  try {
    const res = await DriversService.getDriverInfoById(id);
    dispatch({
      type: "SET_DRIVER_INFO_BY_ID",
      payload: res.data,
    });
  } catch (err) {
    toast.error("Failed to fetch driver info");
  }
};

export const setDriverOrdersList = (id) => async (dispatch) => {
  try {
    const res = await DriversService.getDriverOrdersList(id);
    dispatch({
      type: "SET_DRIVER_ORDERS_LIST",
      payload: res.data,
    });
  } catch (err) {
    toast.error("Failed to fetch orders list");
  }
};

export const setDriversTabValue = (value) => ({
  type: "SET_DRIVER_TAB_VALUE",
  payload: value,
});

export const closeDriverDetailsForTripDrawer = () => ({
  type: "CLOSE_DRIVER_DETAILS_FOR_TRIP_DRAWER",
});

export const setToggleOrderDetailsModal = (orderId) => ({
  type: "SET_TOGGLE_ORDERS_DETAILS_MODAL",
  payload: orderId,
});

export const setOrderDetailsInfo = () => async (dispatch, getState) => {
  const state = getState(),
    orderId = getSelectedOrderId({ state });

  try {
    const res = await DriversService.getOrderDetailsInfo(orderId);
    dispatch({
      type: "SET_ORDER_DETAILS_INFO",
      payload: res.data,
    });
  } catch (err) {
    toast.error("Failed to fetch order details");
  }
};

export const setOrderCartDetails = () => async (dispatch, getState) => {
  const state = getState(),
    orderId = getSelectedOrderId({ state });

  try {
    const res = await DriversService.getOrderCartDetails(orderId);
    dispatch({
      type: "SET_ORDER_CART_DETAILS",
      payload: res.data,
    });
  } catch (err) {
    toast.error("Failed to fetch order cart");
  }
};

export const setIsShowOnMap = (val) => ({
  type: "SET_IS_SHOW_ON_MAP",
  payload: val,
});
