import { updateObject } from "../utility";

const initialState = {
  isShowDriverTrip: false,
  selectedDriver: "",
  selectedDriverByMap: "",
  selectedOrderByMap: "",
  readyDriversList: [],
  readyDriversLength: "",
  clonedDriversList: [],
  driverInfoById: "",
  driverOrdersList: [],
  driverTabValue: 1,
  isOrderDetailsModalOpen: false,
  selectedOrderId: "",
  orderDetailsInfo: "",
  orderCartDetails: [],
  isShowOnMap: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_SHOW_DRIVER_TRIP": {
      return updateObject(state, { isShowDriverTrip: !state.isShowDriverTrip });
    }

    case "HIDE_DRIVER_TRIP_DETAILS": {
      return updateObject(state, { isShowDriverTrip: false });
    }

    case "SELECTED_DRIVER_DATA": {
      return updateObject(state, {
        selectedDriver: action.payload,
      });
    }

    case "SELECTED_DRIVER_DATA_BY_MAP": {
      return updateObject(state, {
        selectedDriverByMap: action.payload,
      });
    }

    case "SELECTED_ORDER_DATA_BY_MAP": {
      return updateObject(state, {
        selectedOrderByMap: action.payload,
      });
    }

    case "SET_READY_DRIVERS_LIST": {
      return updateObject(state, {
        readyDriversList: action.payload,
        readyDriversLength: action.payload.length,
      });
    }

    case "SET_CLONED_DRIVERS_LIST": {
      return updateObject(state, { clonedDriversList: action.payload });
    }

    case "SET_DRIVER_INFO_BY_ID": {
      return updateObject(state, { driverInfoById: action.payload });
    }

    case "SET_DRIVER_ORDERS_LIST": {
      return updateObject(state, { driverOrdersList: action.payload });
    }

    case "SET_DRIVER_TAB_VALUE": {
      return updateObject(state, { driverTabValue: action.payload });
    }

    case "CLOSE_DRIVER_DETAILS_FOR_TRIP_DRAWER": {
      return updateObject(state, { isShowDriverTrip: false });
    }

    case "SET_TOGGLE_ORDERS_DETAILS_MODAL": {
      return updateObject(state, {
        isOrderDetailsModalOpen: !state.isOrderDetailsModalOpen,
        selectedOrderId: action.payload,
      });
    }

    case "SET_ORDER_DETAILS_INFO": {
      return updateObject(state, { orderDetailsInfo: action.payload });
    }

    case "SET_ORDER_CART_DETAILS": {
      return updateObject(state, { orderCartDetails: action.payload });
    }

    case "SET_IS_SHOW_ON_MAP": {
      return updateObject(state, { isShowOnMap: action.payload });
    }
    default:
      return state;
  }
};

export default reducer;
