import { toast } from 'react-toastify';
//selector
import { getClonedTripsList } from './TripsSelectors';
//api
import TripsService from '../../services/TripsService';

export const setToggleTripDetails = () => ({
	type: 'TOGGLE_SHOW_TRIP_DETAILS',
});

export const setTripsList = (status) => async (dispatch) => {
	const params = {
		status,
	};
	try {
		const res = await TripsService.getTripsList(params);
		dispatch({
			type: 'SET_TRIPS_LIST',
			payload: res.data,
		});

		dispatch({
			type: 'SET_CLONED_TRIPS_LIST',
			payload: res.data,
		});
	} catch (err) {
		toast.error('Failed to fetch trips list');
	}
};

export const setTripsListBySearch = (val) => async (dispatch, getState) => {
	const state = getState();
	const clonedTripList = getClonedTripsList({ state });
	const params = {
		orderId: val,
	};
	if (val) {
		try {
			const res = await TripsService.getTripsListBySearch(params);
			dispatch({
				type: 'SET_TRIPS_LIST',
				payload: res.data,
			});
		} catch (err) {
			toast.error('Failed to fetch trips list');
		}
	} else {
		dispatch({
			type: 'SET_TRIPS_LIST',
			payload: clonedTripList,
		});
	}
};

export const setSelectedTrip = (selectedTrip) => ({
	type: 'SELECTED_TRIP_DATA',
	payload: selectedTrip,
});

export const setTripDetailsData = (id) => async (dispatch) => {
	try {
		const res = await TripsService.getTripDetailsData(id);
		dispatch({
			type: 'SET_TRIP_DETAILS_DATA',
			payload: res.data,
		});
		dispatch({
			type: 'SET_NEW_TRIP_ORDERS_DATA',
			payload: [],
		});
	} catch (err) {
		toast.error('Failed to fetch orders list');
	}
};

export const setTripTabValue = (value) => ({
	type: 'SET_TRIP_TAB_VALUE',
	payload: value,
});

export const closeTripDetailsForDriversDrawer = () => ({
	type: 'CLOSE_TRIP_DETAILS_FOR_DRIVERS_DRAWER',
});
