import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { ReactSortable } from 'react-sortablejs';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import SectionLoader from '../shared/SectionLoader';
//material ui
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import InputBase from '@material-ui/core/InputBase';
import CancelIcon from '@material-ui/icons/Cancel';
//actions
import {
	setConfirmOrdersDispatch,
	setOrdersAutoSorting,
	setScanOrderQrCode,
} from '../../store/dispatch/DispatchActions';
//selectors
import { getDispatchedData, getSelectedGroupId } from '../../store/dispatch/DispatchSelectors';

const useStyles = makeStyles((theme) => ({
	search: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#eee',
		marginBottom: 15,
		marginLeft: 0,
		marginRight: 10,
		width: '90%',
		position: 'relative',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		paddingLeft: 20,
		transition: theme.transitions.create('width'),
	},
}));

const GroupOrdersModalContent = ({ setOpenCamera, setIsScanned, dispatchedOrdersList }) => {
	const classes = useStyles(),
		dispatchedData = useSelector((state) => getDispatchedData({ state })),
		selectedGroupId = useSelector((state) => getSelectedGroupId({ state })),
		[isLoaderOpen, setIsLoaderOpen] = useState(false),
		[ordersList, setOrdersList] = useState([]),
		[orderValue, setOrderValue] = useState(''),
		dispatch = useDispatch();

	useEffect(() => {
		if (dispatchedData.length > 0) {
			const updatedordersList = dispatchedData
				.filter((el) => el.id == selectedGroupId)
				?.pop()?.orders;
			updatedordersList?.forEach((item, i) => {
				item.index = i;
			});
			setOrdersList(updatedordersList);
		}
	}, [dispatchedData]);

	const handleDeleteOrder = (index) => {
		const filteredOrdersList = ordersList.filter((item) => item.index != index),
			clonedDispatchedData = cloneDeep(dispatchedData);
		setOrdersList(filteredOrdersList);
		clonedDispatchedData.forEach((el) => {
			if (el.id == selectedGroupId) {
				el.orders = filteredOrdersList;
			}
		});
		dispatch({
			type: 'SET_UPDATED_DISPATCHED_DATA',
			payload: clonedDispatchedData,
		});
	};

	const handleSortrders = (newList) => {
		const clonedDispatchedData = cloneDeep(dispatchedData);
		clonedDispatchedData.forEach((el) => {
			if (el.id == selectedGroupId) {
				el.orders = newList;
			}
		});
		dispatch({
			type: 'SET_UPDATED_DISPATCHED_DATA',
			payload: clonedDispatchedData,
		});
		setOrdersList(newList);
	};

	const assignedDriver = () => {
		return dispatchedData.find((item) => item.id == selectedGroupId)?.driver?.name;
	};

	const hasOrders = () => {
		if (dispatchedData.find((item) => item.id == selectedGroupId).orders.length > 0) {
			return true;
		} else {
			return false;
		}
	};

	const handleDispatchOrders = async () => {
		if (hasOrders()) {
			setIsLoaderOpen(true);
			await dispatch(setConfirmOrdersDispatch());
			setIsLoaderOpen(false);
		} else {
			toast.error('No orders scanned!');
		}
	};

	const handleAutoSorting = async () => {
		if (assignedDriver()) {
			setIsLoaderOpen(true);
			await dispatch(setOrdersAutoSorting());
			setIsLoaderOpen(false);
		} else {
			toast.error('No driver assigned');
		}
	};

	const handleAddOrderManually = () => {
		if (dispatchedOrdersList.some((el) => el?.order?.orderId == orderValue)) {
			toast.error('Order already taken!');
		} else {
			dispatch(setScanOrderQrCode(orderValue));
			setOrderValue('');
		}
	};

	return (
		<List dense className="orders-modal-wrapper" style={{ width: '100%' }}>
			<div className="add-order-bar">
				<div className={classes.search}>
					<InputBase
						placeholder="Order Number"
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
						inputProps={{ 'aria-label': 'Search' }}
						onChange={(e) => setOrderValue(e.target.value)}
						type="number"
						value={orderValue}
					/>
					<CancelIcon onClick={() => setOrderValue('')} className="cancel-icon" />
				</div>
				<Button
					onClick={handleAddOrderManually}
					variant="contained"
					size="small"
					color="default"
					startIcon={<AddIcon />}
					disabled={!orderValue}
					style={{ width: '10%' }}
				>
					Add
				</Button>
			</div>

			<div className="sorting-btn-wrapper">
				<Button
					onClick={() => {
						setOpenCamera(true);
						setIsScanned(false);
					}}
					variant="contained"
					color="default"
					startIcon={<CameraAltIcon />}
					size="small"
					disabled={isLoaderOpen}
				>
					Scan
				</Button>
				<Button
					onClick={() => handleAutoSorting()}
					variant="contained"
					color="primary"
					startIcon={<UnfoldMoreIcon />}
					size="small"
					disabled={!ordersList.length > 0 || isLoaderOpen}
				>
					Auto Sorting
				</Button>
				<Button
					onClick={() => handleDispatchOrders()}
					variant="contained"
					color="secondary"
					startIcon={<CheckCircleIcon />}
					size="small"
					disabled={!assignedDriver() || isLoaderOpen}
					style={{ backgroundColor: '#22BB33' }}
				>
					Dispatch
				</Button>
			</div>
			<Fragment>
				{isLoaderOpen ? (
					<SectionLoader />
				) : ordersList.length > 0 ? (
					<div id="list">
						<ReactSortable
							list={ordersList}
							setList={(newList) => {
								handleSortrders(newList);
							}}
							handle=".menu-icon"
						>
							{ordersList.map((item, i) => {
								return (
									<div key={i}>
										<ListItem className="list-item" alignItems="flex-start">
											<ListItemText
												secondary={
													<div className="dispatched-order-wrapper">
														<div className="order-details">
															<IconButton
																style={{ padding: 0 }}
																edge="start"
																color="inherit"
																aria-label="menu"
																className="menu-icon"
															>
																<MenuIcon />
															</IconButton>
															<div>
																<p>{`OrderID: ${item?.order?.orderId}`}</p>
																<p>No. of items: {item?.order?.itemCount} </p>
																<p>Time Slot: {item?.order?.deliveryTime} </p>
																<p>Address: {item?.order?.orderRecipient?.address}</p>
																<p>Area: {item?.order?.orderRecipient?.area}</p>
															</div>
														</div>

														<div style={{ width: '20%' }} className="orders-counter">
															<img style={{ width: 60 }} src={item?.order?.productImage} />
														</div>
														<div>
															<IconButton onClick={() => handleDeleteOrder(item.index)} edge="end">
																<DeleteIcon style={{ color: '#f50057' }} />
															</IconButton>
														</div>
													</div>
												}
											/>
										</ListItem>
										<Divider style={{ marginLeft: 0 }} variant="inset" component="li" />
									</div>
								);
							})}
						</ReactSortable>
					</div>
				) : (
					<p style={{ textAlign: 'center' }}>No orders scanned</p>
				)}
			</Fragment>
		</List>
	);
};

export default GroupOrdersModalContent;
