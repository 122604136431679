import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
//service
import DispatchService from '../../services/DispatchService';
import OptimizeService from '../../services/OptimizeService';
//selectors
import { getDispatchedData, getSelectedGroupId } from './DispatchSelectors';

export const setAddNewGroup = () => (dispatch) => {
	dispatch({
		type: 'SET_ADD_NEW_GROUP',
	});
};

export const setDeleteGroup = (id) => (dispatch) => {
	dispatch({
		type: 'SET_DELETE_GROUP',
		id,
	});
};

export const setSelectedGroup = (id) => (dispatch) => {
	dispatch({
		type: 'SET_SELECTED_GROUP',
		id,
	});
};

export const setAssignDriver = (driver) => (dispatch) => {
	dispatch({
		type: 'SET_ASSIGN_DRIVER',
		payload: driver,
	});
};

export const setToggleGroupOrdersModal = () => ({
	type: 'SET_TOGGLE_GROUP_ORDERS_MODAL',
});

export const setScanOrderQrCode = (orderQrCode) => async (dispatch) => {
	try {
		const res = await DispatchService.validateScannedOrder(orderQrCode);
		dispatch({
			type: 'SET_SCAN_ORDER_QR_CODE',
			payload: res.data,
		});
	} catch (err) {
		toast.error(err?.response?.data?.message ?? "Can't scan this order");
	}
};

export const setConfirmOrdersDispatch = () => async (dispatch, getState) => {
	const state = getState(),
		dispatchedData = getDispatchedData({ state }),
		selectedGroupId = getSelectedGroupId({ state });

	const groupDriverId = dispatchedData.find((item) => item.id == selectedGroupId).driver.id;
	const groupOrders = dispatchedData
		.find((item) => item.id == selectedGroupId)
		.orders.map((el) => el.order.orderId);

	const dataBody = [
		{
			driverId: groupDriverId,
			orders: groupOrders,
		},
	];
	try {
		await OptimizeService.getConfirmOptimization(dataBody);
		dispatch(setDeleteGroup(selectedGroupId));
		dispatch(setToggleGroupOrdersModal());
		toast.success('Dispatched successfully');
	} catch (err) {
		toast.error(err?.response?.data?.message);
	}
};

export const setOrdersAutoSorting = () => async (dispatch, getState) => {
	const state = getState(),
		dispatchedData = getDispatchedData({ state }),
		selectedGroupId = getSelectedGroupId({ state }),
		clonedDispatchedData = cloneDeep(dispatchedData);

	const groupDriverId = dispatchedData.find((item) => item.id == selectedGroupId).driver.id;
	const groupOrders = dispatchedData
		.find((item) => item.id == selectedGroupId)
		.orders.map((el) => ({ Id: el.order.orderId, Address: el.order.orderRecipient }));

	const dataBody = {
		drivers: [groupDriverId],
		orders: groupOrders,
	};
	try {
		const res = await OptimizeService.getDoOptimize(dataBody);
		clonedDispatchedData.forEach((item) => {
			if (item.id == selectedGroupId) {
				item.orders.forEach((el, index) => {
					el.order.orderId = res?.data?.[0]?.orders[index];
				});
			}
		});
		dispatch({
			type: 'SET_UPDATED_DISPATCHED_DATA',
			payload: clonedDispatchedData,
		});
		toast.success('Sorted successfully');
	} catch (err) {
		toast.error(err?.response?.data?.message);
	}
};
