import { apiHomeService, apiUserService } from "./apiService/HttpService";
import { setEndPoint } from "./apiService/SetEndPoint";
import { endPoints } from "./apiService/EndPoints";

class AppService {
  static getOpsCenterInfo(...extraParams) {
    return apiHomeService({
      method: "GET",
      url: setEndPoint(endPoints.getOpsInfo, ...extraParams),
    });
  }

  static getAllOpsCenter(...extraParams) {
    return apiUserService({
      method: "GET",
      url: setEndPoint(endPoints.getAllOpsCenters, ...extraParams),
    });
  }
}

export default AppService;
