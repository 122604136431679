import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
//material ui
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
//shared
import SectionLoader from '../../../shared/SectionLoader';
//constants
import { convertToTimeOnly, tripStatus, convertToDateAndTime } from '../../../../constants/Helpers';
//icons
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
//actions
import { setSelectedTrip, setToggleTripDetails } from '../../../../store/trips/TripsActions';
import {
  ShowOrdersDropPinsTrue,
  setDragCenterCoords,
} from '../../../../store/googleMap/GoogleMapActions';
import { closeDriverDetailsForTripDrawer } from '../../../../store/drivers/DriversActions';

const TripListContent = ({ tripsList, isLoaderOpen, tabValue }) => {
  const dispatch = useDispatch();

  const handleSelectedTrip = (item) => {
    dispatch(closeDriverDetailsForTripDrawer());
    dispatch({
      type: 'SELECTED_DRIVER_DATA',
      payload: '',
    });
    dispatch(setDragCenterCoords(''));
    dispatch(setSelectedTrip(item));
    dispatch(setToggleTripDetails());
    dispatch(ShowOrdersDropPinsTrue());
  };

  return (
    <Fragment>
      <List className="trip-list-wrapper">
        {isLoaderOpen ? (
          <SectionLoader />
        ) : (
          <Fragment>
            {tripsList.map((item, i) => (
              <Fragment key={i}>
                <ListItem
                  onClick={() => handleSelectedTrip(item)}
                  className="list-item"
                  alignItems="flex-start"
                  button
                >
                  <ListItemAvatar style={{ width: '20%' }}>
                    <Avatar
                      style={{ backgroundColor: '#1876D1' }}
                      alt={item.driver.name}
                      src="/static/images/avatar/2.jpg"
                    />
                    <span className="MuiTypography-body1 driver-name">{item.driver.name}</span>
                  </ListItemAvatar>
                  <ListItemText
                    style={{ width: '80%' }}
                    primary={`Started: ${item.startDate && convertToDateAndTime(item.startDate)}`}
                    secondary={
                      <Fragment>
                        <Typography component="span" variant="body2" color="textPrimary">
                          <div className="list-content-style">
                            <div>
                              {tabValue == 0 ? (
                                ''
                              ) : (
                                <p>Ended: {item.endDate ? convertToTimeOnly(item.endDate) : ''}</p>
                              )}
                              <Chip
                                style={{
                                  backgroundColor: item.status === 1 ? '#22BB33' : 'lightGray',
                                  color: item.status === 2 ? 'black' : 'white',
                                }}
                                label={tripStatus[item.status]}
                                color={
                                  item.status === 0
                                    ? 'primary'
                                    : item.status === 1
                                    ? 'red'
                                    : item.status === 2
                                    ? 'lightGray'
                                    : 'secondary'
                                }
                              />
                            </div>
                            <div className="orders-counter">
                              <p>{item.totalOrders}</p>
                              <p>orders</p>
                            </div>
                            <ArrowForwardIosIcon className="list-item-icon" />
                          </div>
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </Fragment>
            ))}
          </Fragment>
        )}
      </List>
    </Fragment>
  );
};

export default TripListContent;
