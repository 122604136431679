import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
//components
import TripDetailsHeader from './TripDetailsHeader';
import TripDetailsContent from './TripDetailsContent';
//selector
import { getSelectedTrip } from '../../../../store/trips/TripsSelectors';

const TripDetailsContainer = () => {
  const selectedTrip = useSelector((state) => getSelectedTrip({ state }));

  return (
    <Fragment>
      <TripDetailsHeader selectedTrip={selectedTrip} />
      <TripDetailsContent selectedTrip={selectedTrip} />
    </Fragment>
  );
};

export default TripDetailsContainer;
