import { apiLogisticsService } from "./apiService/HttpService";
import { setEndPoint } from "./apiService/SetEndPoint";
import { endPoints } from "./apiService/EndPoints";

class TripsService {
  static getTripsList(data, ...extraParams) {
    return apiLogisticsService({
      method: "GET",
      url: setEndPoint(endPoints.getTripsList, ...extraParams),
      params: data,
    });
  }

  static getTripDetailsData(...extraParams) {
    return apiLogisticsService({
      method: "GET",
      url: setEndPoint(endPoints.getTripDetailsData, ...extraParams),
    });
  }

  static getTripsListBySearch(data, ...extraParams) {
    return apiLogisticsService({
      method: "GET",
      url: setEndPoint(endPoints.getTripListBySearch, ...extraParams),
      params: data,
    });
  }
}

export default TripsService;
