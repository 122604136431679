import { toast } from "react-toastify";
//api
import OptimizeService from "../../services/OptimizeService";
import DriversService from "../../services/DriversService";
//actions
import { setToggleAssignOrdersModalOpen } from "../app/AppActions";
//selectors
import {
  getSelectedReadyDrivers,
  getSelectedReadyOrders,
} from "./AssignDriverOrdersSelectors";
import { getProposedOptimizedList } from "../assignDriversOrders/AssignDriverOrdersSelectors";

export const setReadyOrdersList = () => async (dispatch) => {
  try {
    const res = await OptimizeService.getReadyOrdersList();
    const updatedList = res.data.map((item) => ({
      ...item,
      selected: false,
    }));
    dispatch({
      type: "SET_READY_ORDERS_LIST",
      payload: updatedList,
    });
  } catch (err) {
    toast.error("Failed to fetch orders");
  }
};

export const setFreeDriversList = (status) => async (dispatch) => {
  const params = {
    status,
  };
  try {
    const res = await DriversService.getDriversList(params);
    dispatch({
      type: "SET_FREE_DRIVERS_LIST",
      payload: res.data,
    });
  } catch (err) {
    toast.error("Failed to fetch drivers list");
  }
};

export const setSelectedReadyOrders = (selectedOrders) => ({
  type: "SET_SELECTED_READY_ORDERS",
  payload: selectedOrders,
});

export const setSelectedReadyDrivers = (selectedDrivers) => ({
  type: "SET_SELECTED_READY_DRIVERS",
  payload: selectedDrivers,
});

export const setToggleSwitchProposedList = () => ({
  type: "SET_TOGGLE_SWITCH_PROPOSED_LIST",
});

export const setOptimizeOrders = () => async (dispatch, getState) => {
  const state = getState(),
    selectedReadyDrivers = getSelectedReadyDrivers({ state }),
    selectedReadyOrders = getSelectedReadyOrders({ state }),
    dataBody = {
      drivers: selectedReadyDrivers,
      orders: selectedReadyOrders,
    };
  try {
    const res = await OptimizeService.getDoOptimize(dataBody);

    dispatch({
      type: "SET_PROPOSED_OPTIMIZED_LIST",
      payload: res.data,
    });
  } catch (err) {
    dispatch(setToggleSwitchProposedList());
    toast.error(
      "Please make sure the address is correct for the selected orders"
    );
  }
};

export const setConfirmOptimization = () => async (dispatch, getState) => {
  const state = getState(),
    optimizedList = getProposedOptimizedList({ state }),
    dataBody = optimizedList;
  try {
    const res = await OptimizeService.getConfirmOptimization(dataBody);
    dispatch(setToggleAssignOrdersModalOpen());
    toast.success("Drivers Assigned");
  } catch (err) {
    toast.error(err?.response?.data?.message);
  }
};

export const sortDriverOrders = ({ driverId, newOrders }) => ({
  type: "SORT_DRIVER_ORDERS",
  driverId,
  newOrders,
});

export const sortOrdersBetweenDrivers = (driversData) => ({
  type: "SORT_ORDERS_BETWEEN_DRIVERS",
  driversData,
});
