import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import PersonIcon from "@material-ui/icons/Person";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SectionLoader from "../../shared/SectionLoader";
import { cloneDeep } from "lodash";
//icons
import MenuIcon from "@material-ui/icons/Menu";
//actions
import {
  setOptimizeOrders,
  setSelectedReadyOrders,
  setSelectedReadyDrivers,
  sortDriverOrders,
  sortOrdersBetweenDrivers,
} from "../../../store/assignDriversOrders/AssignDriversOrdersActions";
//selectors
import {
  getProposedOptimizedList,
  getFreeDriversList,
} from "../../../store/assignDriversOrders/AssignDriverOrdersSelectors";

const SortableOrdersList = () => {
  const proposedOptimziedList = useSelector((state) =>
      getProposedOptimizedList({ state })
    ),
    [isLoaderOpen, setIsLoaderOpen] = useState(true),
    freeDriversList = useSelector((state) => getFreeDriversList({ state })),
    dispatch = useDispatch();

  const fetchProposedOptimizedList = async () => {
    setIsLoaderOpen(true);
    await dispatch(setOptimizeOrders());
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchProposedOptimizedList();
  }, []);

  useEffect(() => {
    //component cleanup
    return () => {
      dispatch(setSelectedReadyOrders([]));
      dispatch(setSelectedReadyDrivers([]));
    };
  }, []);

  const getList = (id) => {
    const clonedProposedOptimizedList = cloneDeep(proposedOptimziedList);

    return clonedProposedOptimizedList.find((el) => el.driverId === +id).orders;
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = cloneDeep(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = cloneDeep(source);
    const destClone = cloneDeep(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      dispatch(
        sortDriverOrders({ driverId: source.droppableId, newOrders: items })
      );
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      dispatch(sortOrdersBetweenDrivers(result));
    }
  };

  return (
    <div>
      {isLoaderOpen ? (
        <SectionLoader />
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          {proposedOptimziedList.map((el, i) => (
            <div key={el.driverId} className='optimized-list-wrapper'>
              <List component='nav' aria-label='main mailbox folders'>
                <ListItem className='optimized-list-header'>
                  <ListItemIcon>
                    <PersonIcon style={{ color: "#1876D0" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      freeDriversList &&
                      freeDriversList.find((item) => item.id === el.driverId)
                        .name
                    }
                  />
                </ListItem>
              </List>
              <Divider />
              <Droppable droppableId={el.driverId.toString()}>
                {(provided) => (
                  <div style={{ minHeight: 24 }} ref={provided.innerRef}>
                    {el.orders.map((item, index) => (
                      <Draggable
                        key={item}
                        draggableId={item.toString()}
                        index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <ListItem className='order-item-text'>
                              <ListItemText primary={`Order# ${item}`} />
                              <MenuIcon />
                            </ListItem>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      )}
    </div>
  );
};

export default SortableOrdersList;
