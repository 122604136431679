import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
//loader
import SectionLoader from '../shared/SectionLoader';
//actions
import { setFreeDriversList } from '../../store/assignDriversOrders/AssignDriversOrdersActions';
import { setAssignDriver } from '../../store/dispatch/DispatchActions';
//selectors
import { getFreeDriversList } from '../../store/assignDriversOrders/AssignDriverOrdersSelectors';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '55%',
		backgroundColor: theme.palette.background.paper,
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: '#eee',
		marginBottom: 8,
		marginLeft: 0,
		width: '100%',
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '12ch',
			'&:focus': {
				width: '20ch',
			},
		},
	},
}));

const DriversListModalContent = ({ setIsDriversListModalOpen }) => {
	const classes = useStyles(),
		freeDriversList = useSelector((state) => getFreeDriversList({ state })),
		[isLoaderOpen, setIsLoaderOpen] = useState(false),
		[clonedDriversList, setClonedDriversList] = useState([]),
		dispatch = useDispatch();

	const fetchFreeDriversList = async () => {
		setIsLoaderOpen(true);
		await dispatch(setFreeDriversList(0));
		setIsLoaderOpen(false);
	};

	useEffect(() => {
		fetchFreeDriversList();
	}, []);

	useEffect(() => {
		if (clonedDriversList.length == 0) {
			setClonedDriversList(freeDriversList);
		}
	}, [freeDriversList]);

	const handleAssignDriver = (driver) => {
		dispatch(setAssignDriver(driver));
		setIsDriversListModalOpen((prev) => !prev);
	};

	const handleSearchDriver = (e) => {
		if (e.target.value) {
			let searchResult = clonedDriversList.filter((item) =>
				item.phone.includes(e.target.value.toLowerCase())
			);
			dispatch({
				type: 'SET_FREE_DRIVERS_LIST',
				payload: searchResult,
			});
		} else {
			dispatch({
				type: 'SET_FREE_DRIVERS_LIST',
				payload: clonedDriversList,
			});
		}
	};

	useEffect(() => {
		return () => {
			dispatch({
				type: 'SET_FREE_DRIVERS_LIST',
				payload: [],
			});
			setClonedDriversList([]);
		};
	}, []);

	return (
		<List
			dense
			className={classes.root}
			style={{ width: '100%' }}
			className="free-drivers-list-wrapper"
		>
			{isLoaderOpen ? (
				<SectionLoader />
			) : (
				<Fragment>
					<div className={classes.search}>
						<div className={classes.searchIcon}>
							<SearchIcon />
						</div>
						<InputBase
							placeholder="Search driver phone"
							classes={{
								root: classes.inputRoot,
								input: classes.inputInput,
							}}
							inputProps={{ 'aria-label': 'Search' }}
							onChange={handleSearchDriver}
							type="number"
						/>
					</div>
					{freeDriversList.map((item, i) => {
						return (
							<ListItem onClick={() => handleAssignDriver(item)} key={item.id} button>
								<ListItemAvatar>
									<Avatar
										style={{ backgroundColor: '#f50057', width: 35, height: 35 }}
										alt={item.name}
										src="/static/images/avatar/2.jpg"
									/>
								</ListItemAvatar>
								<ListItemText primary={item.name} />
								<Button variant="contained" color="primary" size="small">
									Assign
								</Button>
							</ListItem>
						);
					})}
				</Fragment>
			)}
		</List>
	);
};

export default DriversListModalContent;
