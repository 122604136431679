import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//react google maps
import {
	withGoogleMap,
	GoogleMap,
	withScriptjs,
	Marker,
	InfoWindow,
	DirectionsRenderer,
} from 'react-google-maps';
import Geocode from 'react-geocode';
import WindowInfoComponent from './windowInfoComponent';
import CancelIcon from '@material-ui/icons/Cancel';
import { cloneDeep } from 'lodash';
//map styles
import mapDarkStyles from '../../../constants/mapDarkStyles.json';
import mapLightStyles from '../../../constants/mapLightStyles.json';
//actions
import {
	setSelectedDriverByMap,
	setSelectedOrderByMap,
	setSelectedDriver,
	setToggleShowDriverTrip,
	setHideDriverTripDetails,
	setIsShowOnMap,
} from '../../../store/drivers/DriversActions';
import { setDragCenterCoords } from '../../../store/googleMap/GoogleMapActions';
//selectors
import { getIsDarkMode, getOpsCenterInfo, getDefaultZoom } from '../../../store/app/AppSelectors';
import {
	getSelectedDriver,
	getClonedDriversList,
	getSelectedDriverByMap,
	getSelectedOrderByMap,
	getDriverTabValue,
	getIsShowOnMap,
} from '../../../store/drivers/DriversSelectors';
import { getTripDetailsData, getNewTripOrdersData } from '../../../store/trips/TripsSelectors';
import {
	getIsShowOrdersDropPins,
	getDragCenterCoords,
} from '../../../store/googleMap/GoogleMapSelectors';

let GoogleMapsAPI = 'AIzaSyAU4sXTG1XBwWin0hk4tAcUo9PbhSZL3gQ';

const AsyncMap = withScriptjs(
	withGoogleMap((props) => (
		<GoogleMap
			google={props.google}
			icon={{ url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' }}
			ref={props.onMapMounted}
			defaultZoom={props.defaultZoom}
			defaultCenter={{ lat: props.latValue, lng: props.lngValue }}
			zoom={props.selectedDriver ? 17 : props.defaultZoom}
			center={{
				lat: props.selectedDriver
					? props.selectedDriver.latitude
					: props.dargCenterCoords
					? props.dargCenterCoords.lat
					: props.latValue,
				lng: props.selectedDriver
					? props.selectedDriver.longitude
					: props.dargCenterCoords
					? props.dargCenterCoords.lng
					: props.lngValue,
			}}
			direction={props.direction}
			onDragEnd={props.handleDrag}
			setDirections={props.setDirections}
			disableDefaultUI={false}
			options={{
				styles: props.isDarkMode ? mapDarkStyles : mapLightStyles,
			}}
		>
			<Fragment>
				<Marker
					position={{ lat: props.latValue, lng: props.lngValue }}
					icon={{
						url: `https://cdn.floward.com/web/Files/attachment/group2024@3x-637586606142691557.png`,
						scaledSize: { width: 35, height: 50 },
					}}
				/>
				{props.isShowOrdersDropPins
					? props.tripOrdersList.map((item, i) => (
							<Marker
								key={i}
								position={{ lat: +item.address.lat, lng: +item.address.lng }}
								icon={{
									url: `https://cdn.floward.com/web/Files/attachment/shutterstock_11378660f8-%5Bconverted%5D-637587502302647304.png`,
									scaledSize: { width: 25, height: 40 },
								}}
								onMouseOver={() => props.dispatch(setSelectedOrderByMap(item))}
								onMouseOut={() => props.dispatch(setSelectedOrderByMap(''))}
							/>
					  ))
					: props.driversList.map((item, i) => (
							<Marker
								key={i}
								position={{ lat: item.latitude, lng: item.longitude }}
								icon={{
									url:
										props.tabValue === 0
											? `https://cdn.floward.com/web/Files/attachment/shutterstock_113786608-%5Bconverted%5D-637586682730564485.png`
											: `https://cdn.floward.com/web/Files/attachment/shutterstock_113786608-%5Bconverted%5D-637586641885531539.png`,
									scaledSize: { width: 25, height: 40 },
								}}
								onMouseOver={() => props.dispatch(setSelectedDriverByMap(item))}
								onMouseOut={() => props.dispatch(setSelectedDriverByMap(''))}
								onClick={() => {
									props.dispatch(setIsShowOnMap(false));
									props.dispatch(setSelectedDriver(item));
									props.dispatch(setToggleShowDriverTrip());
								}}
							/>
					  ))}
			</Fragment>

			{props.selectedDriverByMap && (
				<InfoWindow
					position={{
						lat: props.selectedDriverByMap.latitude,
						lng: props.selectedDriverByMap.longitude,
					}}
				>
					<Fragment>
						<CancelIcon
							onClick={() => {
								props.dispatch(setHideDriverTripDetails());
								props.dispatch(setSelectedDriverByMap(''));
								props.dispatch(setDragCenterCoords(''));
							}}
							className="window-info-close-icon"
						/>
						<WindowInfoComponent
							dispatch={useDispatch()}
							selectedDriver={props.selectedDriverByMap}
						/>
					</Fragment>
				</InfoWindow>
			)}

			{props.selectedDriver && (
				<InfoWindow
					position={{
						lat: props.selectedDriver.latitude,
						lng: props.selectedDriver.longitude,
					}}
				>
					<Fragment>
						<CancelIcon
							onClick={() => {
								props.dispatch(setHideDriverTripDetails());
								props.dispatch(setSelectedDriver(''));
								props.dispatch(setDragCenterCoords(''));
							}}
							className="window-info-close-icon"
						/>
						<WindowInfoComponent selectedDriver={props.selectedDriver} />
					</Fragment>
				</InfoWindow>
			)}

			{props.selectedOrderByMap && (
				<InfoWindow
					position={{
						lat: +props.selectedOrderByMap.address.lat,
						lng: +props.selectedOrderByMap.address.lng,
					}}
				>
					<Fragment>
						<WindowInfoComponent selectedOrder={props.selectedOrderByMap} />
					</Fragment>
				</InfoWindow>
			)}

			{props.isShowOrdersDropPins &&
				props.tripOrdersList.map((item, i) => (
					<InfoWindow
						key={i}
						position={{
							lat: +item.address.lat,
							lng: +item.address.lng,
						}}
						zIndex={-1000}
					>
						<Fragment>
							<WindowInfoComponent orderInfo={i + 1} />
						</Fragment>
					</InfoWindow>
				))}

			{props.directions && props.isShowOrdersDropPins && (
				<DirectionsRenderer
					directions={props.directions}
					options={{ suppressMarkers: true }}
					// options={{ suppressPolylines: true }}
				/>
			)}
		</GoogleMap>
	))
);

const MapComponent = ({ google }) => {
	const refs = {},
		isDarkMode = useSelector((state) => getIsDarkMode({ state })),
		tabValue = useSelector((state) => getDriverTabValue({ state })),
		selectedDriver = useSelector((state) => getSelectedDriver({ state })),
		selectedDriverByMap = useSelector((state) => getSelectedDriverByMap({ state })),
		isShowOnMap = useSelector((state) => getIsShowOnMap({ state })),
		selectedOrderByMap = useSelector((state) => getSelectedOrderByMap({ state })),
		driversList = useSelector((state) => getClonedDriversList({ state })),
		tripOrdersList = useSelector((state) => getTripDetailsData({ state })),
		isShowOrdersDropPins = useSelector((state) => getIsShowOrdersDropPins({ state })),
		opsCenter = useSelector((state) => getOpsCenterInfo({ state })),
		defaultZoom = useSelector((state) => getDefaultZoom({ state })),
		dargCenterCoords = useSelector((state) => getDragCenterCoords({ state })),
		[directions, setDirections] = useState(null),
		newOrdersList = useSelector((state) => getNewTripOrdersData({ state })),
		dispatch = useDispatch();

	Geocode.setApiKey(GoogleMapsAPI);

	const onMapMounted = (ref) => {
		refs.map = ref;
	};

	const handleDrag = async () => {
		const center = refs.map.getCenter();
		let newLat = center.lat(),
			newLng = center.lng();
		if (selectedDriver && isShowOnMap) {
			dispatch(setSelectedDriver(''));
			dispatch(setDragCenterCoords({ lat: newLat, lng: newLng }));
		} else {
			dispatch(setDragCenterCoords({ lat: newLat, lng: newLng }));
		}
	};

	//get lat & lng from address or postCode
	useEffect(() => {
		if (tripOrdersList.length > 0 && isShowOrdersDropPins) {
			const updatedArray = [];
			const clonedTripOrdersList = cloneDeep(tripOrdersList);
			for (let i = 0; i < clonedTripOrdersList.length; i++) {
				if (clonedTripOrdersList[i].address) {
					Geocode.fromAddress(
						clonedTripOrdersList[i].address.postalCode
							? `${clonedTripOrdersList[i].address.postalCode} ${clonedTripOrdersList[i].address.addressLineOne} ${clonedTripOrdersList[i].address.country}`
							: `${clonedTripOrdersList[i].address.area} ${clonedTripOrdersList[i].address.addressLineOne} ${clonedTripOrdersList[i].address.country}`
					).then((response) => {
						const { lat, lng } = response.results[0].geometry.location;
						clonedTripOrdersList[i].address.lat = lat.toString();
						clonedTripOrdersList[i].address.lng = lng.toString();
						updatedArray.push(clonedTripOrdersList[i]);
						if (i === clonedTripOrdersList.length - 1) {
							dispatch({
								type: 'SET_NEW_TRIP_ORDERS_DATA',
								payload: updatedArray,
							});
						}
					});
				}
			}
		}
	}, [tripOrdersList]);

	//generate route for the available lat & lng
	useEffect(() => {
		if (window.google && newOrdersList.length > 0) {
			const DirectionsService = new window.google.maps.DirectionsService();
			DirectionsService.route(
				{
					origin: new window.google.maps.LatLng(+opsCenter.Lat, +opsCenter.Lng),
					destination: new window.google.maps.LatLng(
						newOrdersList.slice(-1)[0].address.lat
							? +newOrdersList.slice(-1)[0].address.lat
							: +opsCenter.Lat,
						newOrdersList.slice(-1)[0].address.lng
							? +newOrdersList.slice(-1)[0].address.lng
							: +opsCenter.Lng
					),
					waypoints: newOrdersList.map((item) => ({
						location: new window.google.maps.LatLng(+item.address.lat, +item.address.lng),
					})),
					travelMode: window.google.maps.TravelMode.DRIVING,
					optimizeWaypoints: true,
				},
				(result, status) => {
					if (status === window.google.maps.DirectionsStatus.OK) {
						setDirections(result);
					} else {
						console.error(`error fetching directions ${result}`);
					}
				}
			);
		}
	}, [newOrdersList]);

	return (
		<Fragment>
			<div style={{ height: '100%' }}>
				<Fragment>
					<AsyncMap
						latValue={+opsCenter.Lat}
						lngValue={+opsCenter.Lng}
						isDarkMode={isDarkMode}
						tabValue={tabValue}
						defaultZoom={defaultZoom}
						dargCenterCoords={dargCenterCoords}
						opsCenter={opsCenter}
						driversList={driversList}
						tripOrdersList={newOrdersList}
						isShowOrdersDropPins={isShowOrdersDropPins}
						selectedDriver={selectedDriver}
						handleDrag={handleDrag}
						selectedDriverByMap={selectedDriverByMap}
						selectedOrderByMap={selectedOrderByMap}
						directions={directions}
						setDirections={setDirections}
						dispatch={dispatch}
						google={google}
						onMapMounted={onMapMounted}
						googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
						loadingElement={<div style={{ height: '100%' }} />}
						containerElement={<div style={{ height: '100%' }} />}
						mapElement={<div className="map" style={{ height: '100%' }} />}
					/>
				</Fragment>
			</div>
		</Fragment>
	);
};

export default MapComponent;
