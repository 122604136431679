import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//actions
import { setToggleShowDriverTrip } from '../../../../store/drivers/DriversActions';
import { setDragCenterCoords } from '../../../../store/googleMap/GoogleMapActions';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
}));

const DriverTripHeader = ({ selectedDriver }) => {
	const classes = useStyles(),
		dispatch = useDispatch();

	const handleBackToDriversList = () => {
		dispatch({
			type: 'SELECTED_DRIVER_DATA',
			payload: '',
		});
		dispatch(setDragCenterCoords(''));
		dispatch(setToggleShowDriverTrip());
	};

	return (
		<Fragment>
			<div className={` ${classes.toolbar} driver-trip-header`}>
				<div className="driver-trip-title">
					<ArrowBackIcon onClick={() => handleBackToDriversList()} className="back-icon" />
					<span
						className="MuiTypography-body1"
						style={{
							textAlign: 'center',
							color: 'black',
							fontWeight: 'bold',
						}}
					>
						Driver# {selectedDriver.id}
					</span>
				</div>
			</div>
			<Divider />
		</Fragment>
	);
};

export default DriverTripHeader;
