import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
//components
import SectionLoader from "../../shared/SectionLoader";
//actions
import { setOrderCartDetails } from "../../../store/drivers/DriversActions";
//selectors
import { getOrderCartDetails } from "../../../store/drivers/DriversSelectors";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const OrderCartDetails = () => {
  const classes = useStyles(),
    orderCartDetails = useSelector((state) => getOrderCartDetails({ state })),
    [isLoaderOpen, setIsLoaderOpen] = useState(false),
    dispatch = useDispatch();

  const fetchOrderCartData = async () => {
    setIsLoaderOpen(true);
    await dispatch(setOrderCartDetails());
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchOrderCartData();
  }, []);

  return (
    <div>
      {isLoaderOpen ? (
        <SectionLoader />
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>QTY</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Subtotal</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderCartDetails.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <img src={item.image} style={{ width: 60 }} />
                  </TableCell>
                  <TableCell>{item.proName}</TableCell>
                  <TableCell>{item.qty}</TableCell>
                  <TableCell>{item.price}</TableCell>
                  <TableCell>{item.qty * item.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default OrderCartDetails;
