export const ICON_OPTIONS = {
  USE_DEFAULT: { displayName: 'Use default', icon: null },
  ORANGE_PIN: {
    displayName: 'Orange pin',
    icon: {
      path:
        'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.' +
        '13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.' +
        '12 2.5 2.5-1.12 2.5-2.5 2.5z',
      fillOpacity: 0.9,
      scale: 1.5,
      fillColor: '#fcb503',
      anchor: { x: 12, y: 23 },
    },
  },
  GREEN_ARROW: {
    displayName: 'Green arrow',
    icon: {
      path: 'M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z',
      fillOpacity: 0.9,
      scale: 1.2,
      fillColor: '#03fc2c',
      anchor: { x: 12, y: 12 },
    },
  },
};
