import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
//material ui
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
//loader
import SectionLoader from "../../../shared/SectionLoader";
//constants
import { convertToDateAndTime } from "../../../../constants/Helpers";
//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
//actions
import {
  setToggleShowDriverTrip,
  setSelectedDriver,
  setIsShowOnMap,
} from "../../../../store/drivers/DriversActions";
import { closeTripDetailsForDriversDrawer } from "../../../../store/trips/TripsActions";
import { showOrdersDropPinsFalse } from "../../../../store/googleMap/GoogleMapActions";

const DriversListContent = ({ driversList, isLoaderOpen }) => {
  const dispatch = useDispatch();

  const handleSelectedDriver = (item) => {
    dispatch(closeTripDetailsForDriversDrawer());
    dispatch(setIsShowOnMap(false));
    dispatch(setSelectedDriver(item));
    dispatch(showOrdersDropPinsFalse());
    dispatch(setToggleShowDriverTrip());
  };

  const showOnMapHandler = (e, item) => {
    e.stopPropagation();
    dispatch(closeTripDetailsForDriversDrawer());
    dispatch(showOrdersDropPinsFalse());
    dispatch(setSelectedDriver(item));
    dispatch(setIsShowOnMap(true));
  };

  return (
    <Fragment>
      <List className='driver-list-wrapper'>
        {isLoaderOpen ? (
          <SectionLoader />
        ) : (
          <Fragment>
            {driversList.map((item, i) => (
              <Fragment key={i}>
                <ListItem
                  button
                  onClick={() => handleSelectedDriver(item)}
                  className='list-item'
                  alignItems='flex-start'>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "#f50057" }}
                      alt={item.name}
                      src='/static/images/avatar/2.jpg'
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.name}
                    secondary={
                      <Fragment>
                        <Typography
                          component='span'
                          variant='body2'
                          // className={classes.inline}
                          color='textPrimary'>
                          <div className='list-content-style'>
                            <div>
                              <p style={{ marginTop: 5 }}>
                                Contact: {item.phone}
                              </p>
                              <p>
                                Last seen: {convertToDateAndTime(item.lastSeen)}
                              </p>
                              <Button
                                onClick={(e) => showOnMapHandler(e, item)}
                                size='small'
                                variant='contained'
                                color='primary'
                                className='show-on-map-btn'>
                                Show on map <PersonPinCircleIcon />
                              </Button>
                            </div>
                            <div className='orders-counter'>
                              <p>{item.totalOrders}</p>
                              <p>orders</p>
                            </div>
                            <ArrowForwardIosIcon className='list-item-icon' />
                          </div>
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
              </Fragment>
            ))}
          </Fragment>
        )}
      </List>
    </Fragment>
  );
};

export default DriversListContent;
