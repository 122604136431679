import React from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import LMFSMap from './LMFSMap';

const GoogleMapsAPI = 'AIzaSyAU4sXTG1XBwWin0hk4tAcUo9PbhSZL3gQ';

const render = (status) => <p>{status}</p>;

const LMFSMapComponent = () => {
  return (
    <Wrapper apiKey={GoogleMapsAPI} render={render} version="beta" libraries={['journeySharing']}>
      <LMFSMap />
    </Wrapper>
  );
};

export default LMFSMapComponent;
