import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import cloneDeep from 'lodash/cloneDeep';
//icons
import Person from '@material-ui/icons/Person';
//loader
import SectionLoader from '../../../components/shared/SectionLoader';
//actions
import {
	setSelectedReadyDrivers,
	setFreeDriversList,
} from '../../../store/assignDriversOrders/AssignDriversOrdersActions';
//selectors
import { getFreeDriversList } from '../../../store/assignDriversOrders/AssignDriverOrdersSelectors';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '55%',
		backgroundColor: theme.palette.background.paper,
	},
}));

let updatedSelectedReadyDrivers = [];

const FreeDriversList = () => {
	const classes = useStyles(),
		freeDriversList = useSelector((state) => getFreeDriversList({ state })),
		[selectedDrivers, setSelectedDrivers] = useState([]),
		[isLoaderOpen, setIsLoaderOpen] = useState(false),
		dispatch = useDispatch();

	const fetchFreeDriversList = async () => {
		setIsLoaderOpen(true);
		await dispatch(setFreeDriversList(0));
		setIsLoaderOpen(false);
	};

	useEffect(() => {
		fetchFreeDriversList();
	}, []);

	const handleSelectDriver = (e, id) => {
		const clonedFreeDriversList = cloneDeep(freeDriversList);
		if (selectedDrivers.includes(parseInt(id))) {
			clonedFreeDriversList.forEach((item) => {
				if (item.id == id) {
					item.selected = false;
				}
			});
			dispatch({
				type: 'SET_FREE_DRIVERS_LIST',
				payload: clonedFreeDriversList,
			});
			let filteredSelectedDrivers = updatedSelectedReadyDrivers.filter((el) => el != parseInt(id));
			updatedSelectedReadyDrivers = filteredSelectedDrivers;
			setSelectedDrivers(filteredSelectedDrivers);
			dispatch(setSelectedReadyDrivers(filteredSelectedDrivers));
		} else {
			clonedFreeDriversList.forEach((item) => {
				if (item.id == id) {
					item.selected = true;
				}
			});
			dispatch({
				type: 'SET_FREE_DRIVERS_LIST',
				payload: clonedFreeDriversList,
			});
			updatedSelectedReadyDrivers.push(parseInt(id));
			setSelectedDrivers(updatedSelectedReadyDrivers);
			dispatch(setSelectedReadyDrivers(updatedSelectedReadyDrivers));
		}
	};

	const handleSelectAllDrivers = (e) => {
		let clonedFreeDrivers = cloneDeep(freeDriversList);
		updatedSelectedReadyDrivers = [];
		if (e.target.checked) {
			clonedFreeDrivers.forEach((item) => {
				item.selected = true;
				updatedSelectedReadyDrivers.push(item.id);
			});
			dispatch({
				type: 'SET_FREE_DRIVERS_LIST',
				payload: clonedFreeDrivers,
			});
			setSelectedDrivers(updatedSelectedReadyDrivers);
			dispatch(setSelectedReadyDrivers(updatedSelectedReadyDrivers));
		} else {
			clonedFreeDrivers.forEach((item) => {
				item.selected = false;
			});
			dispatch({
				type: 'SET_FREE_DRIVERS_LIST',
				payload: clonedFreeDrivers,
			});
			setSelectedDrivers([]);
			dispatch(setSelectedReadyDrivers([]));
		}
	};

	//cleanup
	useEffect(() => {
		return () => {
			updatedSelectedReadyDrivers = [];
		};
	}, []);

	return (
		<List dense className={classes.root}>
			{isLoaderOpen ? (
				<SectionLoader />
			) : (
				<Fragment>
					<div className="free-drivers-list-title">
						<div className="list-title-wrapper">
							<Person style={{ marginRight: 5 }} />
							<p>Free Drivers</p>
						</div>
						<div className="all-free-drivers-checkbox">
							<ListItem>
								<ListItemText primary={`Select all (${freeDriversList.length})`} />
								<Checkbox
									edge="end"
									onChange={(e) => handleSelectAllDrivers(e)}
									defaultChecked={false}
								/>
							</ListItem>
						</div>
					</div>
					{freeDriversList.map((item, i) => {
						return (
							<ListItem onClick={(e) => handleSelectDriver(null, item.id)} key={i} button>
								<ListItemAvatar>
									<Avatar
										style={{ backgroundColor: '#f50057', width: 35, height: 35 }}
										alt={item.name}
										src="/static/images/avatar/2.jpg"
									/>
								</ListItemAvatar>
								<ListItemText primary={item.name} />
								<ListItemSecondaryAction>
									<input
										onChange={(e) => handleSelectDriver(e, item.id)}
										type="checkbox"
										checked={item.selected}
										style={{ width: 16, height: 16 }}
									></input>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}
				</Fragment>
			)}
		</List>
	);
};

export default FreeDriversList;
