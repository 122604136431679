import { updateObject } from "../utility";

const initialState = {
  showOrdersDropPins: false,
  dragCenterCoords: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_ORDERS_DROP_PINS_TRUE": {
      return updateObject(state, {
        showOrdersDropPins: true,
      });
    }

    case "SHOW_ORDERS_DROP_PINS_FALSE": {
      return updateObject(state, {
        showOrdersDropPins: false,
      });
    }

    case "SET_DRAG_CENTER_COORDS": {
      return updateObject(state, {
        dragCenterCoords: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
