import { toast } from 'react-toastify';
import GoogleService from '../../services/GoogleService';

export const setGoogleDriversList = () => async (dispatch) => {
  try {
    const res = await GoogleService.getGoogleDriversList();
    dispatch({
      type: 'SET_GOOGLE_DRIVERS_LIST',
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong');
  }
};

export const setGoogleTasksList = (vehicleId) => async (dispatch) => {
  const params = {
    filter: `delivery_vehicle_id=${vehicleId}`,
  };
  try {
    const res = await GoogleService.getGoogleTasksList(vehicleId ? params : '');
    dispatch({
      type: 'SET_GOOGLE_TASKS_LIST',
      payload: res?.data?.tasks ?? [],
    });
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong');
  }
};

export const setDeliveryVehicleId = (driverId) => ({
  type: 'SET_DELIVERY_VEHICLE_ID',
  payload: driverId,
});

export const setLMFSToggleDriverTasks = () => ({
  type: 'SET_LMFS_TOGGLE_DRIVER_TASKS',
});
