import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Fab from '@material-ui/core/Fab';
import cloneDeep from 'lodash/cloneDeep';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//icons
import ShoppingCart from '@material-ui/icons/ShoppingCart';
//loader
import SectionLoader from '../../../components/shared/SectionLoader';
//actions
import {
	setReadyOrdersList,
	setSelectedReadyOrders,
} from '../../../store/assignDriversOrders/AssignDriversOrdersActions';
//selectors
import { getReadyOrdersList } from '../../../store/assignDriversOrders/AssignDriverOrdersSelectors';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '55%',
		backgroundColor: theme.palette.background.paper,
	},
	extendedIcon: {
		marginLeft: theme.spacing(1),
	},
}));

let readySelectedAllOrders = [];
let readySelectedOrdersByZone = [];
let filteredReadySelectedOrdersByZone = [];

const ReadyOrdersList = () => {
	const classes = useStyles(),
		[selectedOrders, setSelectedOrders] = useState([]),
		[isLoaderOpen, setIsLoaderOpen] = useState(false),
		readyOrders = useSelector((state) => getReadyOrdersList({ state })),
		[totalReadyOrdersIds, setTotalReadyOrdersIds] = useState([]),
		dispatch = useDispatch();

	const fetchReadyOrdersList = async () => {
		setIsLoaderOpen(true);
		await dispatch(setReadyOrdersList());
		setIsLoaderOpen(false);
	};

	useEffect(() => {
		fetchReadyOrdersList();
	}, []);

	useEffect(() => {
		let extractedReadyOrdersIds = [];
		readyOrders.forEach((item) => {
			extractedReadyOrdersIds.push(...item.orders);
		});
		setTotalReadyOrdersIds(extractedReadyOrdersIds);
	}, [readyOrders]);

	const handleSelectedOrder = (order) => {
		const clonedOrders = cloneDeep(readyOrders);
		if (order.selected) {
			clonedOrders.forEach((item) => {
				item.orders.forEach((el) => {
					if (el.id == order.id) {
						el.selected = false;
					}
				});
			});
			let checkedItems = selectedOrders.filter((el) => el.Id != parseInt(order.id));
			setSelectedOrders(checkedItems);
			dispatch(setSelectedReadyOrders(checkedItems));
			dispatch({
				type: 'SET_READY_ORDERS_LIST',
				payload: clonedOrders,
			});
		} else {
			clonedOrders.forEach((item) => {
				item.orders.forEach((el) => {
					if (el.id == order.id) {
						el.selected = true;
					}
				});
			});
			setSelectedOrders((prev) => [...prev, { Id: order.id, Address: order.address }]);
			dispatch(
				setSelectedReadyOrders([...selectedOrders, { Id: order.id, Address: order.address }])
			);
			dispatch({
				type: 'SET_READY_ORDERS_LIST',
				payload: clonedOrders,
			});
		}
	};

	const handleSelectAllOrders = (e) => {
		const clonedOrders = cloneDeep(readyOrders);
		if (e.target.checked) {
			clonedOrders.forEach((item) => {
				item.selected = true;
				item.orders.forEach((el) => {
					el.selected = true;
					readySelectedAllOrders.push({ Id: el.id, Address: el.address });
				});
			});
			setSelectedOrders(readySelectedAllOrders);
			dispatch(setSelectedReadyOrders(readySelectedAllOrders));
			dispatch({
				type: 'SET_READY_ORDERS_LIST',
				payload: clonedOrders,
			});
		} else {
			readySelectedAllOrders = [];
			clonedOrders.forEach((item) => {
				item.selected = false;
				item.orders.forEach((el) => {
					el.selected = false;
				});
			});
			setSelectedOrders([]);
			dispatch(setSelectedReadyOrders([]));
			dispatch({
				type: 'SET_READY_ORDERS_LIST',
				payload: clonedOrders,
			});
		}
	};

	const handleSelectZoneOrders = (e, zone) => {
		const clonedOrders = cloneDeep(readyOrders);
		if (e.target.checked) {
			clonedOrders.forEach((item) => {
				if (item.zone == zone) {
					item.selected = true;
					item.orders.forEach((el) => {
						el.selected = true;
						readySelectedOrdersByZone.push({ Id: el.id, Address: el.address });
					});
				}
				setSelectedOrders(readySelectedOrdersByZone);
				dispatch(setSelectedReadyOrders(readySelectedOrdersByZone));
				dispatch({
					type: 'SET_READY_ORDERS_LIST',
					payload: clonedOrders,
				});
			});
		} else {
			clonedOrders.forEach((item) => {
				if (item.zone == zone) {
					item.selected = false;
					item.orders.forEach((el) => {
						el.selected = false;
						if (selectedOrders.map((selectedEl) => selectedEl.Id).includes(el.id)) {
							filteredReadySelectedOrdersByZone = readySelectedOrdersByZone.filter(
								(orderEl) => orderEl.Id != parseInt(el.id)
							);
							readySelectedOrdersByZone = filteredReadySelectedOrdersByZone;
						}
					});
				}
			});
			setSelectedOrders(readySelectedOrdersByZone);
			dispatch(setSelectedReadyOrders(readySelectedOrdersByZone));
			dispatch({
				type: 'SET_READY_ORDERS_LIST',
				payload: clonedOrders,
			});
		}
	};

	//cleanup
	useEffect(() => {
		return () => {
			readySelectedAllOrders = [];
			readySelectedOrdersByZone = [];
			filteredReadySelectedOrdersByZone = [];
			setTotalReadyOrdersIds([]);
		};
	}, []);

	return (
		<List dense className={classes.root}>
			{isLoaderOpen ? (
				<SectionLoader />
			) : (
				<Fragment>
					<div className="free-drivers-list-title">
						<div className="list-title-wrapper">
							<ShoppingCart style={{ marginRight: 5 }} />
							<p>Ready Orders</p>
						</div>
						<div>
							<ListItem>
								<ListItemText primary={`Select all (${totalReadyOrdersIds.length})`} />
								<Checkbox
									edge="end"
									onChange={(e) => handleSelectAllOrders(e)}
									// inputProps={{ 'aria-labelledby': labelId }}
									defaultChecked={false}
								/>
							</ListItem>
						</div>
					</div>
					{readyOrders.map((item, i) => {
						return (
							<div key={i}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Checkbox
										edge="end"
										onChange={(e) => handleSelectZoneOrders(e, item.zone, item)}
										// inputProps={{ 'aria-labelledby': labelId }}
										defaultChecked={false}
										checked={item.selected}
									/>
									<span
										style={{
											marginLeft: 5,
											fontWeight: 'bold',
											fontFamily: 'sans-serif',
											fontSize: 14,
										}}
									>
										Zone: {item.zone}
									</span>
								</div>
								{item.orders.map((el) => (
									<div
										onClick={() => handleSelectedOrder(el)}
										key={i}
										className="ready-orders-wrapper"
									>
										<Fab
											style={{
												backgroundColor: el.selected ? '#22BB33' : 'lightGrey',
												color: el.selected ? 'white' : 'black',
												boxShadow: 'none',
												padding: '0 10px',
											}}
											color="default"
											variant="extended"
											size="medium"
										>
											#{el.id}
											<CheckCircleOutlineIcon
												style={{ visibility: el.selected ? 'visible' : 'hidden' }}
												className={classes.extendedIcon}
											/>
										</Fab>
									</div>
								))}
							</div>
						);
					})}
				</Fragment>
			)}
		</List>
	);
};

export default ReadyOrdersList;
