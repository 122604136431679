import React from "react";
import FreeDriversList from "./FreeDriversList";
import ReadyOrdersList from "./ReadyOrdersList";

const AssignOrdersModalContent = () => {
  return (
    <div className='assign-orders-wrapper'>
      <FreeDriversList />
      <ReadyOrdersList />
    </div>
  );
};

export default AssignOrdersModalContent;
