import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./scss/styles.scss";
// import "./assets/fonts/Gilroy-Medium.ttf";
// import "./assets/fonts/FedraSansArabic4.otf";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./store/rootReducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

export const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById("root")
);
