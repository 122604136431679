import { updateObject } from '../utility';

const initialState = {
	isShowTripDetails: false,
	selectedTrip: '',
	tripsList: [],
	tripsLength: '',
	clonedTripsList: [],
	tripDetailsData: [],
	tripsNewOrdersData: [],
	tripTabValue: 0,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'TOGGLE_SHOW_TRIP_DETAILS': {
			return updateObject(state, {
				isShowTripDetails: !state.isShowTripDetails,
			});
		}

		case 'SELECTED_TRIP_DATA': {
			return updateObject(state, {
				selectedTrip: action.payload,
			});
		}

		case 'SET_TRIPS_LIST': {
			return updateObject(state, {
				tripsList: action.payload,
				tripsLength: action.payload.length,
			});
		}

		case 'SET_CLONED_TRIPS_LIST': {
			return updateObject(state, { clonedTripsList: action.payload });
		}

		case 'SET_TRIP_DETAILS_DATA': {
			return updateObject(state, { tripDetailsData: action.payload });
		}

		case 'SET_NEW_TRIP_ORDERS_DATA': {
			return updateObject(state, { tripsNewOrdersData: action.payload });
		}

		case 'SET_TRIP_TAB_VALUE': {
			return updateObject(state, { tripTabValue: action.payload });
		}

		case 'CLOSE_TRIP_DETAILS_FOR_DRIVERS_DRAWER': {
			return updateObject(state, { isShowTripDetails: false });
		}

		default:
			return state;
	}
};

export default reducer;
