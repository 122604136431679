export const ShowOrdersDropPinsTrue = () => ({
  type: "SHOW_ORDERS_DROP_PINS_TRUE",
});

export const showOrdersDropPinsFalse = () => ({
  type: "SHOW_ORDERS_DROP_PINS_FALSE",
});

export const setDragCenterCoords = (coords) => ({
  type: "SET_DRAG_CENTER_COORDS",
  payload: coords,
});
