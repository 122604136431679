import { googleService, googleTokenService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class GoogleService {
  static getGoogleFleetToken(...extraParams) {
    return googleTokenService({
      method: 'GET',
      url: setEndPoint(endPoints.getGoogleFleetToken, ...extraParams),
    });
  }

  static getGoogleDriversList(...extraParams) {
    return googleService({
      method: 'GET',
      url: setEndPoint(endPoints.getGoogleDriversList, ...extraParams),
    });
  }

  static getGoogleTasksList(params, ...extraParams) {
    return googleService({
      method: 'GET',
      url: setEndPoint(endPoints.getGoogleTasksList, ...extraParams),
      params,
    });
  }
}

export default GoogleService;
