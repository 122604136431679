import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//actions
import { setLMFSToggleDriverTasks } from '../../../../store/googleLMFS/GoogleLMFSActions';
//selector
import { getDeliveryVehicleId } from '../../../../store/googleLMFS/GoogleLMFSSelectors';

const LMFSDriverTasksHeader = () => {
  const driverId = useSelector((state) => getDeliveryVehicleId({ state })),
    dispatch = useDispatch();

  const handleBackToTripList = () => {
    dispatch(setLMFSToggleDriverTasks());
  };

  return (
    <div className="lmfs-tasks-header">
      <ArrowBackIcon
        onClick={() => handleBackToTripList()}
        style={{ fontFamily: 'Roboto' }}
        className="back-icon"
      />
      {driverId && (
        <div className="driver-id-wrapper">
          <p className="driver-title">DRIVER</p>
          <span className="driver-id">{driverId}</span>
        </div>
      )}
      <Divider />
    </div>
  );
};

export default LMFSDriverTasksHeader;
