import { combineReducers } from 'redux';
import app from '../store/app/AppReducer';
import drivers from '../store/drivers/DriversReducer';
import trips from '../store/trips/TripsReducer';
import googleMap from '../store/googleMap/GoogleMapReducer';
import assignDriversOrders from '../store/assignDriversOrders/AssignDriversOrdersReducer';
import dispatch from '../store/dispatch/DispatchReducer';
import googleLMFS from '../store/googleLMFS/GoogleLMFSReducer';

const rootReducer = combineReducers({
  app,
  drivers,
  trips,
  googleMap,
  assignDriversOrders,
  dispatch,
  googleLMFS,
});

export default rootReducer;
