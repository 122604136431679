import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
//components
import DriverListContainer from './driversList/DriverListContainer';
import DriverTripContainer from './driverTrip/DriverTripContainer';
//selectors
import { getIsRightSideBarOpen, getOpsCenterId } from '../../../store/app/AppSelectors';
import { getIsShowDriverTrip } from '../../../store/drivers/DriversSelectors';

const DriversListComponent = () => {
  const isRightSideBarOpen = useSelector((state) => getIsRightSideBarOpen({ state })),
    isShowDriverTrip = useSelector((state) => getIsShowDriverTrip({ state })),
    opsId = useSelector((state) => getOpsCenterId({ state }));
  return (
    <div>
      <Fragment>
        <Drawer
          anchor={'right'}
          variant={'persistent'} //removes the overlay
          open={isRightSideBarOpen}
        >
          {opsId == 13 ? (
            ''
          ) : (
            <div className="right-side-wrapper">
              {isShowDriverTrip ? <DriverTripContainer /> : <DriverListContainer />}
            </div>
          )}
        </Drawer>
      </Fragment>
    </div>
  );
};

export default DriversListComponent;
