export const getReadyOrdersList = ({ state }) =>
  state.assignDriversOrders.readyOrdersList;

export const getFreeDriversList = ({ state }) =>
  state.assignDriversOrders.freeDriversList;

export const getSelectedReadyDrivers = ({ state }) =>
  state.assignDriversOrders.selectedDrivers;

export const getSelectedReadyOrders = ({ state }) =>
  state.assignDriversOrders.selectedOrders;

export const getProposedOptimizedList = ({ state }) =>
  state.assignDriversOrders.proposedOptimizedList;

export const getIsSwitchProposedList = ({ state }) =>
  state.assignDriversOrders.isSwitchProposedList;
