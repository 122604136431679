import React from 'react';
//components
import GroupsComponent from '../components/dispatch/GroupsComponent';

const DispatchPage = () => {
	return (
		<div>
			<GroupsComponent />
		</div>
	);
};

export default DispatchPage;
