import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//components
import DriversListContent from "./DriversListContent";
import DriversListHeader from "./DriversListHeader";
//actions
import { setDriversList } from "../../../../store/drivers/DriversActions";
//selectors
import {
  getReadyDriversList,
  getDriverTabValue,
} from "../../../../store/drivers/DriversSelectors";

const DriverListContainer = () => {
  const driversList = useSelector((state) => getReadyDriversList({ state })),
    [isLoaderOpen, setIsLoaderOpen] = useState(false),
    tabValue = useSelector((state) => getDriverTabValue({ state })),
    dispatch = useDispatch();

  const fetchDriversList = async () => {
    setIsLoaderOpen(true);
    await dispatch(setDriversList(tabValue));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchDriversList();
  }, []);

  return (
    <Fragment>
      <DriversListHeader setIsLoaderOpen={setIsLoaderOpen} />
      <DriversListContent
        driversList={driversList}
        isLoaderOpen={isLoaderOpen}
      />
    </Fragment>
  );
};

export default DriverListContainer;
