import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//components
import TripListContent from './TripListContent';
import TripListHeader from './TripListHeader';
//actions
import { setTripsList } from '../../../../store/trips/TripsActions';
//selectors
import { getTripsList, getTripTabValue } from '../../../../store/trips/TripsSelectors';

const TripListContainer = () => {
  const tripsList = useSelector((state) => getTripsList({ state })),
    [isLoaderOpen, setIsLoaderOpen] = useState(false),
    tabValue = useSelector((state) => getTripTabValue({ state })),
    dispatch = useDispatch();

  const fetchTripsList = async () => {
    setIsLoaderOpen(true);
    await dispatch(setTripsList(tabValue));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchTripsList();
  }, []);

  return (
    <Fragment>
      <TripListHeader setIsLoaderOpen={setIsLoaderOpen} />
      <TripListContent tripsList={tripsList} isLoaderOpen={isLoaderOpen} tabValue={tabValue} />
    </Fragment>
  );
};

export default TripListContainer;
