export const getIsShowTripDetails = ({ state }) => state.trips.isShowTripDetails;

export const getTripsList = ({ state }) => state.trips.tripsList;

export const getTripsLength = ({ state }) => state.trips.tripsLength;

export const getClonedTripsList = ({ state }) => state.trips.clonedTripsList;

// export const getDriverInfoById = ({ state }) => state.drivers.driverInfoById;

export const getTripDetailsData = ({ state }) => state.trips.tripDetailsData;

export const getNewTripOrdersData = ({ state }) => state.trips.tripsNewOrdersData;

export const getSelectedTrip = ({ state }) => state.trips.selectedTrip;

export const getTripTabValue = ({ state }) => state.trips.tripTabValue;
