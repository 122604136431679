export const getIsLoggedIn = ({ state }) => state.app.isLoggedIn;
export const getDecodedToken = ({ state }) => state.app.decodedToken;
export const getIsDarkMode = ({ state }) => state.app.isDarkMode;
export const getIsLeftSideBarOpen = ({ state }) => state.app.isLeftSideBarOpen;
export const getIsRightSideBarOpen = ({ state }) => state.app.isRightSideBarOpen;

export const getIsAssignOrdersModalOpen = ({ state }) => state.app.isAssignOrdersModalOpen;

export const getOpsCenterInfo = ({ state }) => state.app.opsCenterInfo;
export const getOpsCenterId = ({ state }) => state.app.opsId;

export const getIsOpsModalOpen = ({ state }) => state.app.isOpsModalOpen;

export const getAllOpsCenters = ({ state }) => state.app.opsCenters;

export const getDefaultZoom = ({ state }) => state.app.defaultZoom;

export const getIsMobileSize = ({ state }) => state.app.isMobileSize;
export const getIsDesktopSize = ({ state }) => state.app.isDesktopSize;

export const getGoogleToken = ({ state }) => state.app.googleToken;
