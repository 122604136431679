import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
// //components
import TripListContainer from './tripsList/TripListContainer';
import TripDetailsContainer from './tripDetails/TripDetailsContainer';
import LMFSDriverTasksContainer from './LMFSDriverTasks/LMFSDriverTasksContainer';
import LMFSTripsContainer from './LMFSTrips/LMFSTripsContainer';
// //selectors
import { getIsLeftSideBarOpen, getOpsCenterId } from '../../../store/app/AppSelectors';
import { getIsShowTripDetails } from '../../../store/trips/TripsSelectors';
import { getIsShowLMFSDriverTasks } from '../../../store/googleLMFS/GoogleLMFSSelectors';

const TripsComponent = () => {
  const isLeftSideBarOpen = useSelector((state) => getIsLeftSideBarOpen({ state })),
    isShowTripDetails = useSelector((state) => getIsShowTripDetails({ state })),
    isShowLMFSDriverTasks = useSelector((state) => getIsShowLMFSDriverTasks({ state })),
    opsId = useSelector((state) => getOpsCenterId({ state }));

  return (
    <div>
      <Fragment>
        <Drawer anchor={'left'} variant={'persistent'} open={isLeftSideBarOpen}>
          {opsId == 13 ? (
            <div className="left-side-wrapper">
              {isShowLMFSDriverTasks ? <LMFSDriverTasksContainer /> : <LMFSTripsContainer />}
            </div>
          ) : (
            <div className="left-side-wrapper">
              {isShowTripDetails ? <TripDetailsContainer /> : <TripListContainer />}
            </div>
          )}
        </Drawer>
      </Fragment>
    </div>
  );
};

export default TripsComponent;
