import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//constants
import { history, hubStagingUrl, hubProdUrl, isProductionEnvironment } from './constants/Helpers';
//pages
import HomePage from './pages/HomePage';
import DispatchPage from './pages/DispatchPage';
//components
import Navbar from './components/shared/Navbar';
//actions
import { setOpsInfo, setAllOpsCenters } from './store/app/AppActions';
//selectors
import { getIsLoggedIn, getOpsCenterId } from './store/app/AppSelectors';
//api
import GoogleService from './services/GoogleService';

function App() {
  const isLoggedIn = useSelector((state) => getIsLoggedIn({ state }));
  const opsId = useSelector((state) => getOpsCenterId({ state }));

  const dispatch = useDispatch();

  useEffect(() => {
    const queryString = window.location.search,
      urlParams = new URLSearchParams(queryString),
      token = urlParams.get('token'),
      opsId = urlParams.get('opsId');
    if (token && opsId) {
      localStorage.setItem('LOGISTICS_TOKEN', token);
      dispatch({
        type: 'DECODED_TOKEN',
      });
      localStorage.setItem('opsId', opsId);
      dispatch({
        type: 'SET_CURRENT_OPS',
      });
      history.push('/');
    } else if (isLoggedIn) {
      dispatch({
        type: 'DECODED_TOKEN',
      });
      dispatch({
        type: 'SET_CURRENT_OPS',
      });
    } else {
      if (!isProductionEnvironment) {
        window.location.href = `${hubStagingUrl}?source=${window.location.href}`;
      } else {
        window.location.href = `${hubProdUrl}?source=${window.location.href}`;
      }
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('opsId') && localStorage.getItem('LOGISTICS_TOKEN')) {
      dispatch(setOpsInfo());
      dispatch(setAllOpsCenters());
    }
  }, []);

  const generateGoogleToken = async () => {
    const res = await GoogleService.getGoogleFleetToken();
    localStorage.setItem('GOOGLE_AUTH_TOKEN', res.data.jwt_token);
    dispatch({
      type: 'SET_GOOGLE_TOKEN',
    });
  };

  useEffect(() => {
    if (opsId == 13) {
      generateGoogleToken();
      const interval = setInterval(() => {
        generateGoogleToken();
      }, 1800000);
      return () => clearInterval(interval);
    }
  }, [opsId]);

  return (
    <BrowserRouter>
      <div className="app">
        <Navbar />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/home" render={() => <Redirect to="/" />} />
          <Route path="/dispatch" component={DispatchPage} />
        </Switch>
        <ToastContainer position="bottom-right" />
      </div>
    </BrowserRouter>
  );
}

export default App;
