import { updateObject } from '../utility';

const initialState = {
  driversList: [],
  tasksList: [],
  deliveryVehicleId: '',
  isShowLMFSDriverTasks: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_GOOGLE_DRIVERS_LIST': {
      return updateObject(state, {
        driversList: action.payload,
      });
    }

    case 'SET_GOOGLE_TASKS_LIST': {
      return updateObject(state, {
        tasksList: action.payload,
      });
    }

    case 'SET_DELIVERY_VEHICLE_ID': {
      return updateObject(state, {
        deliveryVehicleId: action.payload,
      });
    }

    case 'SET_LMFS_TOGGLE_DRIVER_TASKS': {
      return updateObject(state, {
        isShowLMFSDriverTasks: !state.isShowLMFSDriverTasks,
      });
    }

    default:
      return state;
  }
};

export default reducer;
