import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class DispatchService {
	static validateScannedOrder(...extraParams) {
		return apiGatewayService({
			method: 'GET',
			url: setEndPoint(endPoints.getValidateOrder, ...extraParams),
		});
	}
}

export default DispatchService;
