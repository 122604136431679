import { apiOrderService, apiLogisticsService } from "./apiService/HttpService";
import { setEndPoint } from "./apiService/SetEndPoint";
import { endPoints } from "./apiService/EndPoints";

class OptimizeService {
  static getReadyOrdersList(...extraParams) {
    return apiOrderService({
      method: "GET",
      url: setEndPoint(endPoints.getReadyOrders, ...extraParams),
    });
  }

  static getDoOptimize(data, ...extraParams) {
    return apiLogisticsService({
      method: "POST",
      url: setEndPoint(endPoints.getDoOptimize, ...extraParams),
      data,
    });
  }

  static getConfirmOptimization(data, ...extraParams) {
    return apiLogisticsService({
      method: "POST",
      url: setEndPoint(endPoints.getConfirmOptimization, ...extraParams),
      data,
    });
  }
}

export default OptimizeService;
