import { cloneDeep } from "lodash";
import { updateObject } from "../utility";
const initialState = {
  readyOrdersList: [],
  freeDriversList: [],
  selectedDrivers: [],
  selectedOrders: [],
  proposedOptimizedList: [],
  isSwitchProposedList: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_READY_ORDERS_LIST": {
      return updateObject(state, { readyOrdersList: action.payload });
    }

    case "SET_FREE_DRIVERS_LIST": {
      return updateObject(state, { freeDriversList: action.payload });
    }

    case "SET_SELECTED_READY_DRIVERS": {
      return updateObject(state, { selectedDrivers: action.payload });
    }

    case "SET_SELECTED_READY_ORDERS": {
      return updateObject(state, { selectedOrders: action.payload });
    }

    case "SET_PROPOSED_OPTIMIZED_LIST": {
      return updateObject(state, { proposedOptimizedList: action.payload });
    }

    case "SET_TOGGLE_SWITCH_PROPOSED_LIST": {
      return updateObject(state, {
        isSwitchProposedList: !state.isSwitchProposedList,
      });
    }

    case "SORT_DRIVER_ORDERS": {
      const { driverId, newOrders } = action,
        clonedProposedOptimizedList = cloneDeep(state.proposedOptimizedList),
        driverToUpdate = clonedProposedOptimizedList.find(
          (el) => el.driverId === +driverId
        );

      driverToUpdate.orders = newOrders;

      return updateObject(state, {
        proposedOptimizedList: clonedProposedOptimizedList,
      });
    }

    case "SORT_ORDERS_BETWEEN_DRIVERS": {
      const clonedData = cloneDeep(action.driversData),
        clonedProposedOptimizedList = cloneDeep(state.proposedOptimizedList);

      Object.keys(clonedData).forEach((driverId) => {
        const driverToUpdate = clonedProposedOptimizedList.find(
          (el) => el.driverId === +driverId
        );
        driverToUpdate.orders = clonedData[driverId];
      });

      return updateObject(state, {
        proposedOptimizedList: clonedProposedOptimizedList,
      });
    }

    default:
      return state;
  }
};

export default reducer;
