import {
  apiLogisticsService,
  apiGatewayService,
} from "./apiService/HttpService";
import { setEndPoint } from "./apiService/SetEndPoint";
import { endPoints } from "./apiService/EndPoints";

class DriversService {
  static getDriversList(data, ...extraParams) {
    return apiLogisticsService({
      method: "GET",
      url: setEndPoint(endPoints.getDriversList, ...extraParams),
      params: data,
    });
  }

  static getDriverInfoById(...extraParams) {
    return apiLogisticsService({
      method: "GET",
      url: setEndPoint(endPoints.getDriverInfoById, ...extraParams),
    });
  }

  static getDriverOrdersList(...extraParams) {
    return apiLogisticsService({
      method: "GET",
      url: setEndPoint(endPoints.getDriverOrdersList, ...extraParams),
    });
  }

  static getOrderDetailsInfo(...extraParams) {
    return apiGatewayService({
      method: "GET",
      url: setEndPoint(endPoints.getOrderDetailsInfo, ...extraParams),
    });
  }

  static getOrderCartDetails(...extraParams) {
    return apiGatewayService({
      method: "GET",
      url: setEndPoint(endPoints.getOrderCartDetails, ...extraParams),
    });
  }
}

export default DriversService;
