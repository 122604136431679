import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//material ui
import { makeStyles, fade } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
//actions
import {
	setDriversList,
	setDriversTabValue,
	setSelectedDriver,
} from '../../../../store/drivers/DriversActions';
import { showOrdersDropPinsFalse } from '../../../../store/googleMap/GoogleMapActions';
import { closeTripDetailsForDriversDrawer } from '../../../../store/trips/TripsActions';
//selectors
import {
	getDriverTabValue,
	getClonedDriversList,
	getReadyDriversLength,
} from '../../../../store/drivers/DriversSelectors';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '12ch',
			'&:focus': {
				width: '20ch',
			},
		},
	},
}));

const DriversListHeader = ({ setIsLoaderOpen }) => {
	const tabValue = useSelector((state) => getDriverTabValue({ state })),
		clonedDriversList = useSelector((state) => getClonedDriversList({ state })),
		driversLength = useSelector((state) => getReadyDriversLength({ state })),
		dispatch = useDispatch(),
		classes = useStyles();

	const handleChange = async (event, newValue) => {
		dispatch(showOrdersDropPinsFalse());
		dispatch(closeTripDetailsForDriversDrawer());
		dispatch(setSelectedDriver(''));
		dispatch(setDriversTabValue(newValue));
		setIsLoaderOpen(true);
		await dispatch(setDriversList(newValue));
		setIsLoaderOpen(false);
	};

	const handleSearchDriver = (e) => {
		if (e.target.value) {
			let searchResult = clonedDriversList.filter((item) =>
				item.name.toLowerCase().includes(e.target.value.toLowerCase())
			);
			dispatch({
				type: 'SET_READY_DRIVERS_LIST',
				payload: searchResult,
			});
		} else {
			dispatch({
				type: 'SET_READY_DRIVERS_LIST',
				payload: clonedDriversList,
			});
		}
	};

	return (
		<Fragment>
			<div className={`${classes.toolbar} drivers-list-title`}>
				<div className={classes.search}>
					<div className={classes.searchIcon}>
						<SearchIcon />
					</div>
					<InputBase
						placeholder="Search driver"
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
						inputProps={{ 'aria-label': 'Search' }}
						onChange={handleSearchDriver}
					/>
				</div>
			</div>
			<Divider />
			<Paper square className={classes.root}>
				<Tabs
					value={tabValue}
					onChange={handleChange}
					variant="fullWidth"
					indicatorColor="secondary"
					textColor="secondary"
					aria-label="icon label tabs example"
				>
					<Tab icon={<FreeBreakfastIcon />} label={`FREE ${tabValue === 0 ? driversLength : ''}`} />
					<Tab icon={<LocalShippingIcon />} label={`BUSY ${tabValue === 1 ? driversLength : ''}`} />
				</Tabs>
			</Paper>
			<Divider />
		</Fragment>
	);
};

export default DriversListHeader;
