import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//material ui
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
//shared
import SectionLoader from '../../../shared/SectionLoader';
//actions
import { setGoogleTasksList } from '../../../../store/googleLMFS/GoogleLMFSActions';
//selectors
import {
  getGoogleTasksList,
  getDeliveryVehicleId,
} from '../../../../store/googleLMFS/GoogleLMFSSelectors';
//tasks temp dummy data
import { LMFSTasksList } from '../../../../constants/Helpers';

const LMFSDriverTasksContent = () => {
  // const LMFSTasksList = useSelector((state) => getGoogleTasksList({ state })),
  const driverId = useSelector((state) => getDeliveryVehicleId({ state })),
    [isLoaderOpen, setIsLoaderOpen] = useState(false),
    dispatch = useDispatch();

  const fetchLMFSTasksList = async () => {
    setIsLoaderOpen(true);
    await dispatch(setGoogleTasksList(driverId));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchLMFSTasksList();
  }, []);

  return (
    <Fragment>
      {isLoaderOpen ? (
        <SectionLoader />
      ) : (
        <TableContainer component={Paper}>
          {LMFSTasksList.length > 0 ? (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">#</TableCell>
                  <TableCell align="center">Order ID</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Time</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {LMFSTasksList.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell align="center">{row.name.slice(29)}</TableCell>
                    <TableCell align="center">
                      <Chip
                        style={{
                          backgroundColor: '#22BB33',
                          color: 'white',
                          marginTop: 0,
                        }}
                        label="SUCCESS"
                        size="small"
                      />
                    </TableCell>
                    <TableCell align="center">{row.taskDuration}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <p style={{ textAlign: 'center', color: 'red', fontFamily: 'Roboto' }}>
              No orders found
            </p>
          )}
        </TableContainer>
      )}
    </Fragment>
  );
};

export default LMFSDriverTasksContent;
