import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
//components
import LMFSDriversTripsList from './LMFSDriversTripsList';
import LMFSTripsHeader from './LMFSTripsHeader';
import { LMFSDriversTrip } from '../../../../constants/Helpers';

const LMFSTripsContainer = () => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false),
    dispatch = useDispatch();

  return (
    <Fragment>
      <LMFSTripsHeader setIsLoaderOpen={setIsLoaderOpen} />
      <LMFSDriversTripsList tripsList={LMFSDriversTrip} isLoaderOpen={isLoaderOpen} />
    </Fragment>
  );
};

export default LMFSTripsContainer;
