import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import SectionLoader from '../../../shared/SectionLoader';
//constants
import { orderStatusIcon, convertToDateAndTime } from '../../../../constants/Helpers';
import Modal from '../../../shared/Modal';
import OrderDetailsContent from '../../../modals/orderDetailsModal/OrderDetailsContent';
//actions
import { setTripDetailsData } from '../../../../store/trips/TripsActions';
import { setToggleOrderDetailsModal } from '../../../../store/drivers/DriversActions';
//selectors
import { getIsShowTripDetails, getTripDetailsData } from '../../../../store/trips/TripsSelectors';
import { getIsOrderDetailsModalOpen } from '../../../../store/drivers/DriversSelectors';

const TripDetailsContent = ({ selectedTrip }) => {
	const dispatch = useDispatch(),
		isShowTripDetails = useSelector((state) => getIsShowTripDetails({ state })),
		tripDetailsData = useSelector((state) => getTripDetailsData({ state })),
		[isLoaderOpen, setIsLoaderOpen] = useState(false),
		isOrderDetailsModalOpen = useSelector((state) => getIsOrderDetailsModalOpen({ state })),
		[orderId, setOrderId] = useState('');

	const fetchTripDetails = async (id) => {
		setIsLoaderOpen(true);
		await dispatch(setTripDetailsData(id));
		setIsLoaderOpen(false);
	};

	useEffect(() => {
		fetchTripDetails(selectedTrip.id);
	}, []);

	const handleOpenOrderModal = (orderId) => {
		dispatch(setToggleOrderDetailsModal(orderId));
		setOrderId(orderId);
	};

	const handleCloseModal = () => {
		dispatch(setToggleOrderDetailsModal(''));
	};

	return (
		<div className="driver-trip-wrapper">
			{isOrderDetailsModalOpen && (
				<Modal
					isOpen={isOrderDetailsModalOpen}
					handleSubmit={handleCloseModal}
					closeModal={handleCloseModal}
					content={<OrderDetailsContent orderId={orderId} />}
					title={`Order# ${orderId}`}
					submitBtnTitle="Close"
				/>
			)}
			{isShowTripDetails ? (
				isLoaderOpen ? (
					<SectionLoader />
				) : (
					<List
						component="nav"
						aria-label="secondary mailbox folders"
						className="trip-details-content"
					>
						{tripDetailsData.length > 0 ? (
							tripDetailsData.map((item, i) => (
								<Fragment key={i}>
									<ListItem
										onClick={() => handleOpenOrderModal(item.orderId)}
										className="list-item"
										alignItems="flex-start"
									>
										<ListItemText
											primary={`Order# ${item.orderId}`}
											secondary={
												<Fragment>
													<Typography component="span" variant="body2" color="textPrimary">
														<div className="list-content-style">
															<div style={{ width: '85%', marginTop: 5 }}>
																<p>Time Slot: {item.timeSlotName}</p>
																<p style={{ marginTop: 5 }}>{`ETA: ${
																	item.eta ? convertToDateAndTime(item.eta) : ''
																}`}</p>
																{item.address && item.address.postalCode && (
																	<p>Postal Code: {item.address?.postalCode}</p>
																)}
																{item.address?.area && <p>Area: {item.address?.area}</p>}
															</div>
															<div style={{ width: '15%' }} className="orders-counter">
																<img style={{ width: 40 }} src={orderStatusIcon[item.status]} />
															</div>
														</div>
													</Typography>
												</Fragment>
											}
										/>
									</ListItem>
									<Divider variant="inset" component="li" />
								</Fragment>
							))
						) : (
							<p className="no-orders-title">No Orders</p>
						)}
					</List>
				)
			) : (
				''
			)}
		</div>
	);
};

export default TripDetailsContent;
