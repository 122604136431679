import React, { Fragment } from "react";
import SortableOrdersList from "./SortableOrdersList";

const SortOrdersModalContent = () => {
  return (
    <Fragment>
      <SortableOrdersList />
    </Fragment>
  );
};

export default SortOrdersModalContent;
