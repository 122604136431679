import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//actions
import { setToggleTripDetails } from '../../../../store/trips/TripsActions';
import {
	showOrdersDropPinsFalse,
	setDragCenterCoords,
} from '../../../../store/googleMap/GoogleMapActions';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
}));

const TripDetailsHeader = ({ selectedTrip }) => {
	const classes = useStyles(),
		dispatch = useDispatch();

	const handleBackToTripList = () => {
		dispatch({
			type: 'SELECTED_TRIP_DATA',
			payload: '',
		});
		dispatch({
			type: 'SET_TRIP_DETAILS_DATA',
			payload: [],
		});
		dispatch({
			type: 'SET_NEW_TRIP_ORDERS_DATA',
			payload: [],
		});
		dispatch(setDragCenterCoords(''));
		dispatch(showOrdersDropPinsFalse());
		dispatch(setToggleTripDetails());
	};

	return (
		<Fragment>
			<div className={` ${classes.toolbar} driver-trip-header`}>
				<div className="driver-trip-title">
					<ArrowBackIcon onClick={() => handleBackToTripList()} className="back-icon" />
					<span
						className="MuiTypography-body1"
						style={{
							textAlign: 'center',
							color: '#4695f6',
							fontWeight: 'bold',
						}}
					>
						Trip# {selectedTrip.id}
					</span>
				</div>
			</div>
			<Divider />
		</Fragment>
	);
};

export default TripDetailsHeader;
