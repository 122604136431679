import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
//components
import SectionLoader from "../../shared/SectionLoader";
//material ui
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DateRangeIcon from "@material-ui/icons/DateRange";
//cconstants
import { dateFormatDDMM } from "../../../constants/Helpers";
//icons
import PersonIcon from "@material-ui/icons/Person";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
//actions
import { setOrderDetailsInfo } from "../../../store/drivers/DriversActions";
//selectors
import { getOrderDetailsInfo } from "../../../store/drivers/DriversSelectors";

const OrderDetailsInfo = () => {
  const orderDetailsInfo = useSelector((state) =>
      getOrderDetailsInfo({ state })
    ),
    [isLoaderOpen, setIsLoaderOpen] = useState(false),
    dispatch = useDispatch();

  const fetchOrderIndoData = async () => {
    setIsLoaderOpen(true);
    await dispatch(setOrderDetailsInfo());
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchOrderIndoData();
  }, []);

  return (
    <Fragment>
      {isLoaderOpen ? (
        <SectionLoader />
      ) : (
        <Grid style={{ width: "100%", marginBottom: 50 }} container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className='order-info-titles'>
              <PersonIcon style={{ marginRight: 5 }} />
              <h4>Sender Details</h4>
            </div>
            {orderDetailsInfo.user && (
              <List>
                {orderDetailsInfo.user.name && (
                  <Fragment>
                    <ListItem>
                      <ArrowRightIcon />
                      <ListItemText
                        primary={`Name: ${orderDetailsInfo?.user?.name}`}
                      />
                    </ListItem>
                    {orderDetailsInfo.user.phone && (
                      <ListItem>
                        <ArrowRightIcon />
                        <ListItemText
                          primary={`Phone: ${orderDetailsInfo?.user?.code} ${orderDetailsInfo?.user?.phone}`}
                        />
                      </ListItem>
                    )}

                    {orderDetailsInfo.user.email && (
                      <ListItem>
                        <ArrowRightIcon />
                        <ListItemText
                          primary={`Email: ${orderDetailsInfo?.user?.email} `}
                        />
                      </ListItem>
                    )}
                  </Fragment>
                )}
              </List>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <div className='order-info-titles'>
              <PersonIcon style={{ marginRight: 5 }} />
              <h4>Recepient Details</h4>
            </div>
            {orderDetailsInfo.recipient && (
              <List>
                {orderDetailsInfo.recipient.recipentName && (
                  <Fragment>
                    <ListItem>
                      <ArrowRightIcon />
                      <ListItemText
                        primary={`Name: ${orderDetailsInfo?.recipient?.recipentName}`}
                      />
                    </ListItem>
                    {orderDetailsInfo.recipient.recipientPhone && (
                      <ListItem>
                        <ArrowRightIcon />
                        <ListItemText
                          primary={`Phone: ${orderDetailsInfo?.recipient?.code} ${orderDetailsInfo?.recipient?.recipientPhone}`}
                        />
                      </ListItem>
                    )}

                    {orderDetailsInfo.recipient.area && (
                      <ListItem>
                        <ArrowRightIcon />
                        <ListItemText
                          primary={`Area: ${orderDetailsInfo?.recipient?.area} `}
                        />
                      </ListItem>
                    )}
                    {orderDetailsInfo.recipient.address && (
                      <ListItem>
                        <ArrowRightIcon />
                        <ListItemText
                          primary={`Address: ${orderDetailsInfo?.recipient?.address}`}
                        />
                      </ListItem>
                    )}
                    {orderDetailsInfo.recipient.extra && (
                      <ListItem>
                        <ArrowRightIcon />
                        <ListItemText
                          primary={`Extra Direction: ${orderDetailsInfo?.recipient?.extra}`}
                        />
                      </ListItem>
                    )}
                    {orderDetailsInfo.recipient.postalCode && (
                      <ListItem>
                        <ArrowRightIcon />
                        <ListItemText
                          primary={`Postal Code: ${orderDetailsInfo?.recipient?.postalCode}`}
                        />
                      </ListItem>
                    )}
                  </Fragment>
                )}
              </List>
            )}
          </Grid>
          <ListItem className='show-hub-btn'>
            <Button size='medium' variant='contained' color='default'>
              <DateRangeIcon /> {dateFormatDDMM(orderDetailsInfo.deliveryDate)},{" "}
              {orderDetailsInfo.deliveryTimeName}
            </Button>
          </ListItem>
        </Grid>
      )}
    </Fragment>
  );
};

export default OrderDetailsInfo;
