import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
//actions
import { setToggleSwitchProposedList } from "../../store/assignDriversOrders/AssignDriversOrdersActions";
//selectors
import { getIsSwitchProposedList } from "../../store/assignDriversOrders/AssignDriverOrdersSelectors";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Modal = ({
  isOpen,
  handleSubmit,
  closeModal,
  content,
  title,
  submitBtnTitle,
}) => {
  const isSwitchProposedList = useSelector((state) =>
      getIsSwitchProposedList({ state })
    ),
    dispatch = useDispatch();
  return (
    <div>
      <Dialog
        onClose={closeModal}
        aria-labelledby='customized-dialog-title'
        open={isOpen}>
        <DialogTitle id='customized-dialog-title' onClose={closeModal}>
          {title}
        </DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions>
          {isSwitchProposedList && (
            <Button
              autoFocus
              onClick={() => dispatch(setToggleSwitchProposedList())}
              color='primary'>
              Back
            </Button>
          )}
          <Button autoFocus onClick={handleSubmit} color='primary'>
            {submitBtnTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Modal;
