import React, { Fragment } from 'react';
//components
import LMFSDriverTasksContent from './LMFSDriverTasksContent';
import LMFSDriverTasksHeader from './LMFSDriverTasksHeader';

const LMFSDriverTasksContainer = () => {
  return (
    <Fragment>
      <LMFSDriverTasksHeader />
      <LMFSDriverTasksContent />
    </Fragment>
  );
};

export default LMFSDriverTasksContainer;
