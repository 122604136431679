import { updateObject } from '../utility';
import jwt_decode from 'jwt-decode';

const initialState = {
  isLoggedIn: !!localStorage.getItem('LOGISTICS_TOKEN'),
  isDarkMode: localStorage.getItem('isDark') === 'true',
  isLeftSideBarOpen: window.screen.width > 790 ? true : false,
  isRightSideBarOpen: window.screen.width > 790 ? true : false,
  isAssignOrdersModalOpen: false,
  decodedToken: '',
  opsCenterInfo: '',
  opsId: localStorage.getItem('opsId') ?? '',
  isOpsModalOpen: false,
  opsCenters: [],
  defaultZoom: 11,
  isMobileSize: window.screen.width < 500,
  isDesktopSize: window.screen.width > 900,
  googleToken: localStorage.getItem('GOOGLE_AUTH_TOKEN') ?? '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IS_LOGGED_IN_TRUE': {
      return updateObject(state, { isLoggedIn: true });
    }

    case 'DECODED_TOKEN': {
      return updateObject(state, {
        decodedToken: jwt_decode(localStorage.getItem('LOGISTICS_TOKEN')),
      });
    }

    case 'SET_CURRENT_OPS': {
      return updateObject(state, {
        opsId: localStorage.getItem('opsId'),
      });
    }

    case 'TOGGLE_DARK_LIGHT_MODE': {
      const isDarkMode = !state.isDarkMode;
      localStorage.setItem('isDark', isDarkMode);
      return updateObject(state, { isDarkMode });
    }
    case 'TOGGLE_LEFT_SIDE_BAR': {
      return updateObject(state, {
        isLeftSideBarOpen: !state.isLeftSideBarOpen,
      });
    }
    case 'TOGGLE_RIGHT_SIDE_BAR': {
      return updateObject(state, {
        isRightSideBarOpen: !state.isRightSideBarOpen,
      });
    }

    case 'TOGGLE_ASSIGN_ORDERS_MODAL_OPEN': {
      return updateObject(state, {
        isAssignOrdersModalOpen: !state.isAssignOrdersModalOpen,
      });
    }

    case 'SET_OPS_CENTER_INFO': {
      return updateObject(state, {
        opsCenterInfo: action.payload,
      });
    }

    case 'TOGGLE_OPS_MODAL_OPEN': {
      return updateObject(state, {
        isOpsModalOpen: !state.isOpsModalOpen,
      });
    }

    case 'SET_ALL_OPS_CENTERS': {
      return updateObject(state, {
        opsCenters: action.payload,
      });
    }

    case 'SET_INCREASE_DEFAULT_ZOOM': {
      return updateObject(state, {
        defaultZoom: state.defaultZoom + 1,
      });
    }

    case 'SET_DECREASE_DEFAULT_ZOOM': {
      return updateObject(state, {
        defaultZoom: state.defaultZoom - 1,
      });
    }

    case 'SET_RESET_DEFAULT_ZOOM': {
      return updateObject(state, {
        defaultZoom: 13,
      });
    }

    case 'SET_GOOGLE_TOKEN': {
      return updateObject(state, {
        googleToken: localStorage.getItem('GOOGLE_AUTH_TOKEN'),
      });
    }

    default:
      return state;
  }
};

export default reducer;
