import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//material ui
import { makeStyles, fade } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';
//icons
import SettingsIcon from '@material-ui/icons/Settings';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
//actions
import {
  setTripsList,
  setTripTabValue,
  setTripsListBySearch,
} from '../../../../store/trips/TripsActions';
//selectors
import { getTripTabValue, getTripsLength } from '../../../../store/trips/TripsSelectors';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const TripListHeader = ({ setIsLoaderOpen }) => {
  const tabValue = useSelector((state) => getTripTabValue({ state })),
    tripsLength = useSelector((state) => getTripsLength({ state })),
    dispatch = useDispatch(),
    classes = useStyles();

  const handleChange = async (event, newValue) => {
    dispatch(setTripTabValue(newValue));
    setIsLoaderOpen(true);
    await dispatch(setTripsList(newValue));
    setIsLoaderOpen(false);
  };

  const handleDelayChange = debounce(async (keyword) => {
    dispatch(setTripsListBySearch(keyword));
  }, 200);

  const handleSearchTrip = (e) => {
    handleDelayChange(e.target.value);
  };

  return (
    <Fragment>
      <div className={`${classes.toolbar} drivers-list-title`}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Order ID"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'Search' }}
            onChange={handleSearchTrip}
          />
        </div>
      </div>
      <Divider />
      <Paper square className={classes.root}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="icon label tabs example"
        >
          <Tab icon={<SettingsIcon />} label={`ACTIVE ${tabValue === 0 ? tripsLength : ''}`} />
          <Tab
            icon={<CheckCircleIcon />}
            label={`COMPLETED ${tabValue === 1 ? tripsLength : ''}`}
          />
        </Tabs>
      </Paper>
      <Divider />
    </Fragment>
  );
};

export default TripListHeader;
