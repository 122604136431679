import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getOpsCenterInfo } from '../../../store/app/AppSelectors';
import { ICON_OPTIONS } from '../../../constants/LMFSMapConfig';

const LMFSMap = () => {
  const opsCenter = useSelector((state) => getOpsCenterInfo({ state }));

  const ref = useRef(null);
  //<string>
  const trackingId = useRef('');
  // <google.maps.journeySharing.FleetEngineShipmentLocationProvider>
  const locationProvider = useRef();
  // <string | undefined>
  const [error, setError] = useState();
  // <MapOptionsModel>
  const mapOptions = useRef({
    showAnticipatedRoutePolyline: true,
    showTakenRoutePolyline: true,
    destinationMarker: ICON_OPTIONS.USE_DEFAULT,
    vehicleMarker: ICON_OPTIONS.USE_DEFAULT,
  });
  // <TaskModel>
  const [task, setTask] = useState({
    status: null,
    type: null,
    outcome: null,
    estimatedCompletionTime: null,
    journeySegments: null,
  });

  const setTrackingId = (newTrackingId) => {
    trackingId.current = newTrackingId;
    if (locationProvider.current) locationProvider.current.trackingId = trackingId.current;
  };

  const authTokenFetcher = async () => {
    // const response = await LmfsService.getLmfsToken();
    return {
      token: localStorage.getItem('GOOGLE_AUTH_TOKEN'),
      expiresInSeconds: 3300,
    };
  };

  useEffect(() => {
    locationProvider.current =
      new window.google.maps.journeySharing.FleetEngineShipmentLocationProvider({
        projectId: 'floward-lmfs',
        authTokenFetcher,
        trackingId: trackingId.current,
        pollingIntervalMillis: 3000,
      });

    locationProvider.current.addListener(
      'error',
      // google.maps.ErrorEvent
      (e) => {
        setError(e.error.message);
      }
    );

    locationProvider.current.addListener(
      'update',
      // google.maps.journeySharing.FleetEngineShipmentLocationProviderUpdateEvent
      (e) => {
        if (e.task) {
          setTask({
            status: e.task.status,
            type: e.task.type,
            outcome: e.task.outcome,
            estimatedCompletionTime: e.task.estimatedCompletionTime,
            journeySegments: e.task.remainingVehicleJourneySegments,
          });
          setError(undefined);
        }
      }
    );
    // google.maps.journeySharing.JourneySharingMapViewOptions
    const mapViewOptions = {
      element: ref.current,
      locationProvider: locationProvider.current,
      anticipatedRoutePolylineSetup: ({ defaultPolylineOptions }) => {
        return {
          polylineOptions: defaultPolylineOptions,
          visible: mapOptions.current.showAnticipatedRoutePolyline,
        };
      },
      takenRoutePolylineSetup: ({ defaultPolylineOptions }) => {
        return {
          polylineOptions: defaultPolylineOptions,
          visible: mapOptions.current.showTakenRoutePolyline,
        };
      },
      destinationMarkerSetup: ({ defaultMarkerOptions }) => {
        if (mapOptions.current.destinationMarker !== ICON_OPTIONS.USE_DEFAULT) {
          defaultMarkerOptions.icon = mapOptions.current.destinationMarker.icon;
        }
        return { markerOptions: defaultMarkerOptions };
      },
      vehicleMarkerSetup: ({ defaultMarkerOptions }) => {
        if (mapOptions.current.vehicleMarker !== ICON_OPTIONS.USE_DEFAULT) {
          // Preserve some default icon properties.
          if (defaultMarkerOptions.icon) {
            defaultMarkerOptions.icon = Object.assign(
              defaultMarkerOptions.icon,
              mapOptions.current.vehicleMarker.icon
            );
          }
        }
        return { markerOptions: defaultMarkerOptions };
      },
    };

    const mapView = new window.google.maps.journeySharing.JourneySharingMapView(mapViewOptions);

    // Provide default zoom & center so the map loads even if trip ID is bad or stale.
    mapView.map.setOptions({
      center: { lat: +opsCenter.Lat, lng: +opsCenter.Lng },
      zoom: 12,
      mapTypeControl: true,
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'satellite', 'hybrid'],
      },
      streetViewControl: true,
    });
  }, [opsCenter]);

  return (
    <>
      <div className="lmfs-container">
        <div className="lmfs-stack"></div>
        <div className="lmfs-mapContainer">
          <div className="lmfs-map" ref={ref} />
        </div>
      </div>
    </>
  );
};

export default LMFSMap;
