import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

export const handleRealoadPage = () => {
  window.location.reload();
};

export const hubStagingUrl = 'https://hub.staging.floward.io/home';
export const hubProdUrl = 'https://hub.floward.io/home';

export const tripStatus = {
  0: 'Created',
  1: 'Started',
  2: 'Ended',
  3: 'Canceled',
};

export const tripDetails = {
  id: 55,
  startDate: '2021-03-20T14:39:40.677',
  endDate: null,
  expectedEndDate: '2021-03-20T14:39:40.673',
  status: 1,
  totalOrders: 6,
  duration: 0,
  expectedDuration: 0,
};

export const tripOrders = [
  {
    id: 87566,
    deliveryDate: '2021-03-17T22:00:00',
    expectedDeliveryDate: '2021-03-17T22:00:00',
    pickupDate: '2021-03-17T22:00:00',
    deliveryStatus: 0,
    orderStatus: 3,
    tripStatus: 1,
    address: 'Asyut, Al Hamraa Ath Thaneyah',
  },
  {
    id: 87721,
    deliveryDate: '2021-03-17T22:00:00',
    expectedDeliveryDate: '2021-03-17T22:00:00',
    pickupDate: '2021-03-17T20:19:10.547',
    deliveryStatus: 0,
    orderStatus: 4,
    tripStatus: 1,
    address: 'Asyut, Al Hamraa Ath Thaneyah, Markaz El-Fath, Assiut Governorate, Egypt',
  },
  {
    id: 87616,
    deliveryDate: '2021-03-17T22:00:00',
    expectedDeliveryDate: '2021-03-17T22:00:00',
    pickupDate: '2021-03-17T20:19:10.547',
    deliveryStatus: 0,
    orderStatus: 5,
    tripStatus: 1,
    address: 'Salmiya',
  },
  {
    id: 87733,
    deliveryDate: '2021-03-17T22:00:00',
    expectedDeliveryDate: '2021-03-20T22:00:00',
    pickupDate: '2021-03-20T17:39:33.46',
    deliveryStatus: 1,
    orderStatus: 4,
    tripStatus: 1,
    address: 'nxkkd',
  },
  {
    id: 87510,
    deliveryDate: '2021-03-17T22:00:00',
    expectedDeliveryDate: '2021-03-20T22:00:00',
    pickupDate: '2021-03-20T17:39:33.46',
    deliveryStatus: 100,
    orderStatus: 3,
    tripStatus: 1,
    address: 'Salmiya',
  },
  {
    id: 87790,
    deliveryDate: '2021-03-17T22:00:00',
    expectedDeliveryDate: '2021-03-20T22:00:00',
    pickupDate: '2021-03-20T17:39:33.46',
    deliveryStatus: 1,
    orderStatus: 5,
    tripStatus: 1,
    address: 'nxkkd',
  },
  {
    id: 87507,
    deliveryDate: '2021-03-17T22:00:00',
    expectedDeliveryDate: '2021-03-20T22:00:00',
    pickupDate: '2021-03-20T17:39:33.46',
    deliveryStatus: 100,
    orderStatus: 3,
    tripStatus: 1,
    address: 'Salmiya',
  },
];

export const convertToDateAndTime = (date) => {
  const dateObject = new Date(date);
  const humanDateFormat = dateObject.toLocaleString();
  return humanDateFormat;
};

export const convertToTimeOnly = (date) => {
  const dateObject = new Date(date);
  const humanDateFormat = dateObject.toLocaleTimeString([], {
    timeStyle: 'short',
  });
  return humanDateFormat;
};

export const dateFormatDDMM = (date) => {
  const dateFormat = new Date(date);
  let dd = String(dateFormat.getDate()).padStart(2, '0'),
    mm = String(dateFormat.getMonth() + 1).padStart(2, '0'),
    yyyy = dateFormat.getFullYear(),
    newDate = dd + '/' + mm + '/' + yyyy;
  return newDate;
};

export const orderStatus = {
  0: 'Picked',
  1: 'Delivering',
  2: 'Delivered',
  3: 'Canceled',
  4: 'Removed',
};

export const orderStatusIcon = {
  0: 'https://cdn.floward.com/web/Files/attachment/picked-637468243940293727.png',
  1: 'https://cdn.floward.com/web/Files/attachment/delivering-637468244443154127.png',
  2: 'https://cdn.floward.com/web/Files/attachment/delivered-637470804169416023.png',
  3: 'https://cdn.floward.com/web/Files/attachment/not-shared-637468243618625157.png',
  4: 'https://cdn.floward.com/web/Files/attachment/not-shared-637468243618625157.png',
};

export const optimizedPropsedList = [
  {
    driverId: 'Hossam',
    orders: [211226, 213591, 213608],
  },
  {
    driverId: 'Ahmad',
    orders: [213629, 213215],
  },
  {
    driverId: 'Ali',
    orders: [212426],
  },
  {
    driverId: 'Kumar',
    orders: [211220, 213363, 209157, 213626],
  },
];

export const isDevelopmentEnvironment = process.env.REACT_APP_ENVIRONMENT === 'development';
export const isProductionEnvironment = process.env.REACT_APP_ENVIRONMENT === 'production';
export const isStagingEnvironment = process.env.REACT_APP_ENVIRONMENT === 'staging';

export const LMFSDriversTrip = {
  deliveryVehicles: [
    {
      name: 'providers/floward-lmfs/deliveryVehicles/222',
      lastLocation: {
        location: {
          latitude: 29.3459343381747,
          longitude: 48.092041835188866,
        },
        speedKmph: 0,
        updateTime: '2022-11-22T12:08:14.888Z',
        speed: 1.3441169620465079e-17,
        speedAccuracy: 6.1528754234313965,
        locationSensor: 'ROAD_SNAPPED_LOCATION_PROVIDER',
        serverTime: '2022-11-22T12:08:15.051209Z',
        latlngAccuracy: 60.512123107910156,
        headingAccuracy: 76.811676025390625,
        isRoadSnapped: false,
      },
      navigationStatus: 'NO_GUIDANCE',
    },
    {
      name: 'providers/floward-lmfs/deliveryVehicles/333',
      lastLocation: {
        location: {
          latitude: 29.346648015967233,
          longitude: 48.092116266489029,
        },
        speedKmph: 0,
        updateTime: '2022-11-22T10:07:24.489Z',
        speed: 0,
        speedAccuracy: 23.190547943115234,
        locationSensor: 'ROAD_SNAPPED_LOCATION_PROVIDER',
        serverTime: '2022-11-22T10:07:24.770765Z',
        latlngAccuracy: 115.26850128173828,
        headingAccuracy: 100.93016052246094,
        isRoadSnapped: false,
      },
      navigationStatus: 'NO_GUIDANCE',
    },
    {
      name: 'providers/floward-lmfs/deliveryVehicles/123123',
      lastLocation: {
        location: {
          latitude: 29.346648015967233,
          longitude: 48.092116266489029,
        },
        speedKmph: 0,
        updateTime: '2022-11-22T10:07:24.489Z',
        speed: 0,
        speedAccuracy: 23.190547943115234,
        locationSensor: 'ROAD_SNAPPED_LOCATION_PROVIDER',
        serverTime: '2022-11-22T10:07:24.770765Z',
        latlngAccuracy: 115.26850128173828,
        headingAccuracy: 100.93016052246094,
        isRoadSnapped: false,
      },
      navigationStatus: 'NO_GUIDANCE',
    },
    {
      name: 'providers/floward-lmfs/deliveryVehicles/444',
      lastLocation: {
        location: {
          latitude: 29.346648015967233,
          longitude: 48.092116266489029,
        },
        speedKmph: 0,
        updateTime: '2022-11-22T10:07:24.489Z',
        speed: 0,
        speedAccuracy: 23.190547943115234,
        locationSensor: 'ROAD_SNAPPED_LOCATION_PROVIDER',
        serverTime: '2022-11-22T10:07:24.770765Z',
        latlngAccuracy: 115.26850128173828,
        headingAccuracy: 100.93016052246094,
        isRoadSnapped: false,
      },
      navigationStatus: 'NO_GUIDANCE',
    },
    {
      name: 'providers/floward-lmfs/deliveryVehicles/222',
      lastLocation: {
        location: {
          latitude: 29.3459343381747,
          longitude: 48.092041835188866,
        },
        speedKmph: 0,
        updateTime: '2022-11-22T12:08:14.888Z',
        speed: 1.3441169620465079e-17,
        speedAccuracy: 6.1528754234313965,
        locationSensor: 'ROAD_SNAPPED_LOCATION_PROVIDER',
        serverTime: '2022-11-22T12:08:15.051209Z',
        latlngAccuracy: 60.512123107910156,
        headingAccuracy: 76.811676025390625,
        isRoadSnapped: false,
      },
      navigationStatus: 'NO_GUIDANCE',
    },
  ],
  totalSize: '2',
};

export const LMFSTasksList = [
  {
    name: 'providers/floward-lmfs/tasks/444666',
    type: 'DELIVERY',
    state: 'OPEN',
    trackingId: '789097',
    deliveryVehicleId: '123123',
    plannedLocation: {
      point: {
        latitude: 25.0753638,
        longitude: 55.1347118,
      },
    },
    taskDuration: '4800s',
  },
  {
    name: 'providers/floward-lmfs/tasks/1231234',
    type: 'PICKUP',
    state: 'CLOSED',
    trackingId: '789097',
    deliveryVehicleId: '123123',
    plannedLocation: {
      point: {
        latitude: 25.1940223,
        longitude: 55.2298701,
      },
    },
    taskDuration: '3600s',
  },
  {
    name: 'providers/floward-lmfs/tasks/1231237',
    type: 'DELIVERY',
    state: 'CLOSED',
    trackingId: '789097',
    deliveryVehicleId: '123123',
    plannedLocation: {
      point: {
        latitude: 25.0805422,
        longitude: 55.1381539,
      },
    },
    taskDuration: '4800s',
  },
  {
    name: 'providers/floward-lmfs/tasks/1231231',
    type: 'PICKUP',
    state: 'CLOSED',
    trackingId: '789097',
    deliveryVehicleId: '123123',
    plannedLocation: {
      point: {
        latitude: 25.197197,
        longitude: 55.2721877,
      },
    },
    taskDuration: '3600s',
  },
  {
    name: 'providers/floward-lmfs/tasks/1231239',
    type: 'DELIVERY',
    state: 'CLOSED',
    trackingId: '789097',
    deliveryVehicleId: '123123',
    plannedLocation: {
      point: {
        latitude: 25.0763518,
        longitude: 55.3634841,
      },
    },
    taskDuration: '4800s',
  },
  {
    name: 'providers/floward-lmfs/tasks/444555',
    type: 'DELIVERY',
    state: 'OPEN',
    trackingId: '789097',
    deliveryVehicleId: '123123',
    plannedLocation: {
      point: {
        latitude: 25.0805422,
        longitude: 55.1381539,
      },
    },
    taskDuration: '3600s',
  },
  {
    name: 'providers/floward-lmfs/tasks/1231233',
    type: 'DELIVERY',
    state: 'CLOSED',
    trackingId: '789097',
    deliveryVehicleId: '123123',
    plannedLocation: {
      point: {
        latitude: 25.0763518,
        longitude: 55.3634841,
      },
    },
    taskDuration: '4800s',
  },
  {
    name: 'providers/floward-lmfs/tasks/1231235',
    type: 'DELIVERY',
    state: 'CLOSED',
    trackingId: '789097',
    deliveryVehicleId: '123123',
    plannedLocation: {
      point: {
        latitude: 25.0763518,
        longitude: 55.3634841,
      },
    },
    taskDuration: '4800s',
  },
  {
    name: 'providers/floward-lmfs/tasks/444666',
    type: 'DELIVERY',
    state: 'OPEN',
    trackingId: '789097',
    deliveryVehicleId: '123123',
    plannedLocation: {
      point: {
        latitude: 25.0753638,
        longitude: 55.1347118,
      },
    },
    taskDuration: '4800s',
  },
  {
    name: 'providers/floward-lmfs/tasks/1231234',
    type: 'PICKUP',
    state: 'CLOSED',
    trackingId: '789097',
    deliveryVehicleId: '123123',
    plannedLocation: {
      point: {
        latitude: 25.1940223,
        longitude: 55.2298701,
      },
    },
    taskDuration: '3600s',
  },
];
