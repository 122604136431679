import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//component
import SectionLoader from '../../shared/SectionLoader';
//actions
import { setAllOpsCenters, setOpsInfo } from '../../../store/app/AppActions';
//selectors
import { getAllOpsCenters } from '../../../store/app/AppSelectors';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

const OpsModalContent = () => {
	const dispatch = useDispatch(),
		opsCenters = useSelector((state) => getAllOpsCenters({ state })),
		currentOps = localStorage.getItem('opsId'),
		updatedCenters = opsCenters.filter((ops) => ops.id != currentOps),
		[isLoaderOpen, setIsLoaderOpen] = useState(false),
		classes = useStyles();

	const fetchOpsCenters = async () => {
		setIsLoaderOpen(true);
		await dispatch(setAllOpsCenters());
		setIsLoaderOpen(false);
	};

	useEffect(() => {
		fetchOpsCenters();
	}, []);

	const handleSwitchOps = (ops) => {
		localStorage.setItem('opsId', ops.id);
		localStorage.setItem('groups', JSON.stringify([]));
		dispatch(setOpsInfo());
		window.location.reload();
	};

	return (
		<div className={classes.root}>
			{isLoaderOpen ? (
				<SectionLoader />
			) : (
				<Grid container>
					<p className="ops-modal-title">
						<span>Global</span>
					</p>
					{updatedCenters
						.filter((ops) => ops.countryID != 2 && ops.countryID != 6)
						.map((item, i) => (
							<Grid
								onClick={() => handleSwitchOps(item)}
								style={{ textAlign: 'center' }}
								item
								xs={3}
								key={i}
							>
								<div className="ops-wrapper">
									<img className="ops-img" src={item.iconFullPath} alt="" />
									<p className="ops-name">{item.nickName}</p>
								</div>
							</Grid>
						))}

					<p className="ops-modal-title">
						<span>Saudi Arabia</span>
					</p>
					{updatedCenters
						.filter((ops) => ops.countryID == 2)
						.map((item, i) => (
							<Grid
								onClick={() => handleSwitchOps(item)}
								style={{ textAlign: 'center' }}
								item
								xs={3}
								key={i}
							>
								<div className="ops-wrapper">
									<img className="ops-img" src={item.iconFullPath} alt="" />
									<p className="ops-name">{item.nickName}</p>
								</div>
							</Grid>
						))}

					<p className="ops-modal-title">
						<span>UAE</span>
					</p>
					{updatedCenters
						.filter((ops) => ops.countryID == 6)
						.map((item, i) => (
							<Grid
								onClick={() => handleSwitchOps(item)}
								style={{ textAlign: 'center' }}
								item
								xs={3}
								key={i}
							>
								<div className="ops-wrapper">
									<img className="ops-img" src={item.iconFullPath} alt="" />
									<p className="ops-name">{item.nickName}</p>
								</div>
							</Grid>
						))}
				</Grid>
			)}
		</div>
	);
};

export default OpsModalContent;
