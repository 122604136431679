import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QrReader from 'react-qr-reader2';
import { toast } from 'react-toastify';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Modal from '../shared/Modal';
import DriversListModalContent from './DriversListModalContent';
import GroupOrdersModalContent from './GroupOrdersModalContent';
import Chip from '@material-ui/core/Chip';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
//actions
import {
	setAddNewGroup,
	setDeleteGroup,
	setSelectedGroup,
	setScanOrderQrCode,
	setToggleGroupOrdersModal,
} from '../../store/dispatch/DispatchActions';
//selectors
import {
	getDispatchedData,
	getSelectedGroupId,
	getIsGroupOrdersModalOpen,
} from '../../store/dispatch/DispatchSelectors';

const useStyles = makeStyles((theme) => ({
	text: {
		padding: theme.spacing(2, 2, 0),
	},
	list: {
		marginBottom: theme.spacing(2),
	},
	appBar: {
		top: 'auto',
		bottom: 0,
	},
	grow: {
		flexGrow: 1,
	},
	fabButton: {
		position: 'absolute',
		zIndex: 1,
		top: -30,
		left: 0,
		right: 0,
		margin: '0 auto',
	},
}));

const GroupsComponent = () => {
	const classes = useStyles(),
		dispatch = useDispatch(),
		dispatchedData = useSelector((state) => getDispatchedData({ state })),
		[dispatchedOrdersList, setDispatchedOrdersList] = useState([]),
		[isDriversListModalOpen, setIsDriversListModalOpen] = useState(false),
		isGroupOrdersModalOpen = useSelector((state) => getIsGroupOrdersModalOpen({ state })),
		selectedGroupId = useSelector((state) => getSelectedGroupId({ state })),
		[openCamera, setOpenCamera] = useState(false),
		[isScanned, setIsScanned] = useState(false);

	let updatedAttrArray = [];

	//get list of total groups orders
	useEffect(() => {
		dispatchedData.map((item) => {
			updatedAttrArray.push(...item.orders);
		});
		setDispatchedOrdersList(updatedAttrArray);
	}, [dispatchedData]);

	const handleAddNewGroup = () => {
		dispatch(setAddNewGroup());
	};

	const handleOpenCamera = (e, id) => {
		e.stopPropagation();
		dispatch(setSelectedGroup(id));
		setOpenCamera(true);
		setIsScanned(false);
	};

	const handleScanOrder = (data) => {
		if (data) {
			if (isScanned) {
				return;
			}
			if (dispatchedOrdersList.some((el) => el?.order?.orderId == data)) {
				toast.error('Order already taken!');
			} else {
				dispatch(setScanOrderQrCode(data));
				setOpenCamera(false);
				setIsScanned(true);
			}
		}
	};

	const handleError = (err) => {
		console.log(err);
	};

	const handleAssignDriver = (e, id) => {
		e.stopPropagation();
		dispatch(setSelectedGroup(id));
		setIsDriversListModalOpen((prev) => !prev);
	};

	const handleOpenGroupOrdersModal = (id) => {
		dispatch(setSelectedGroup(id));
		dispatch(setToggleGroupOrdersModal());
	};

	const handleDeleteGroup = (e, id) => {
		e.stopPropagation();
		dispatch(setDeleteGroup(id));
	};

	const assignedDriver = () => {
		return dispatchedData.find((item) => item.id == selectedGroupId)?.driver?.name;
	};

	const getTotalItems = (item) => {
		return item?.orders?.map((el) => el.order.itemCount)?.reduce((a, b) => a + b, 0);
	};

	return (
		<div className="dispatch" style={{ textAlign: 'center' }}>
			{openCamera ? (
				<div className="camera-wrapper" style={{ marginTop: 50 }}>
					<QrReader
						delay={500}
						facingMode="environment"
						legacyMode={true}
						style={{
							width: '100%',
							height: '100%',
						}}
						onError={handleError}
						onScan={handleScanOrder}
					/>
					<Button
						onClick={() => {
							setOpenCamera(false);
						}}
						variant="contained"
						color="secondary"
						className="camera-close-btn"
					>
						Close
					</Button>
				</div>
			) : (
				<Fragment>
					{isDriversListModalOpen && (
						<Modal
							isOpen={isDriversListModalOpen}
							closeModal={() => setIsDriversListModalOpen((prev) => !prev)}
							content={
								<DriversListModalContent setIsDriversListModalOpen={setIsDriversListModalOpen} />
							}
							title="Free Drivers List"
						/>
					)}
					{isGroupOrdersModalOpen && (
						<Modal
							isOpen={isGroupOrdersModalOpen}
							closeModal={() => dispatch(setToggleGroupOrdersModal())}
							content={
								<GroupOrdersModalContent
									setOpenCamera={setOpenCamera}
									setIsScanned={setIsScanned}
									dispatchedOrdersList={dispatchedOrdersList}
								/>
							}
							title={`Group ${selectedGroupId} ${
								assignedDriver() ? `[${assignedDriver()}]` : `(No driver)`
							}`}
						/>
					)}
					<CssBaseline />
					<Paper square>
						<Typography className={classes.text} variant="h6">
							{dispatchedData.length > 0 ? 'Groups' : 'No groups created'}
						</Typography>
						<List className="driver-list-wrapper ">
							{dispatchedData.map((item) => (
								<Fragment key={item.id}>
									<ListItem
										onClick={() => handleOpenGroupOrdersModal(item.id)}
										className="list-item group-details"
										alignItems="center"
										button
									>
										<ListItemAvatar style={{ width: '25%' }}>
											<Chip
												style={{ fontSize: 12 }}
												label={`Group ${item.id}`}
												variant="outlined"
											/>
										</ListItemAvatar>
										<ListItemText
											style={{ width: '80%', color: item?.orders?.length > 0 ? 'black' : 'grey' }}
											primary={`${item?.orders?.length} order${
												item?.orders?.length > 1 ? `s` : ``
											},     
											${getTotalItems(item)} item${getTotalItems(item) > 1 ? `s` : ``}														
											`}
											secondary={`${item?.driver?.name ? ` [${item?.driver?.name}]` : ''}`}
										/>
										<IconButton onClick={(e) => handleOpenCamera(e, item.id)} edge="end">
											<CameraAltIcon />
										</IconButton>
										<IconButton onClick={(e) => handleAssignDriver(e, item.id)} edge="end">
											<PersonAddIcon color="primary" />
										</IconButton>
										<IconButton onClick={(e) => handleDeleteGroup(e, item.id)} edge="end">
											<DeleteIcon style={{ color: '#f50057' }} />
										</IconButton>
									</ListItem>
									<Divider variant="inset" component="li" />
								</Fragment>
							))}
						</List>
					</Paper>
					<AppBar position="fixed" color="primary" className={classes.appBar}>
						<Toolbar>
							<Fab
								color="secondary"
								aria-label="add"
								className={classes.fabButton}
								style={{ backgroundColor: '#22BB33' }}
							>
								<AddIcon onClick={handleAddNewGroup} />
							</Fab>
							<div className={classes.grow} />
						</Toolbar>
					</AppBar>
				</Fragment>
			)}
		</div>
	);
};

export default GroupsComponent;
