import React, { Fragment } from "react";
import { useSelector } from "react-redux";
//components
import DriverTripHeader from "./DriverTripHeader";
import DriverTripContent from "./DriverTripContent";
//selector
import { getSelectedDriver } from "../../../../store/drivers/DriversSelectors";

const DriverTripContainer = () => {
  const selectedDriver = useSelector((state) => getSelectedDriver({ state }));

  return (
    <Fragment>
      <DriverTripHeader selectedDriver={selectedDriver} />
      <DriverTripContent selectedDriver={selectedDriver} />
    </Fragment>
  );
};

export default DriverTripContainer;
