// Configure your environment
export const APP_CONFIG = {
  logistics: process.env.REACT_APP_LOGISTICS,
  order: process.env.REACT_APP_ORDER,
  gateway: process.env.REACT_APP_GATEWAY,
  home: process.env.REACT_APP_HOME,
  user: process.env.REACT_APP_USER,
  google: process.env.REACT_APP_GOOGLE,
  google_token: process.env.REACT_APP_GOOGLE_TOKEN,
};
