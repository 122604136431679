import React, { Fragment } from "react";
import PersonIcon from "@material-ui/icons/Person";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
//constants
import { convertToDateAndTime } from "../../../constants/Helpers";

const WindowInfoComponent = ({ selectedDriver, selectedOrder, orderInfo }) => {
  return (
    <div className='marker-info-window'>
      {selectedDriver ? (
        <Fragment>
          <h3 style={{marginRight: 10}}>
            <PersonIcon /> {selectedDriver.name}
          </h3>
          <h3>
            <PhoneAndroidIcon />
            {selectedDriver.phone}
          </h3>
          <h3>
            <ShoppingCartIcon /> {selectedDriver.totalOrders} orders
          </h3>
        </Fragment>
      ) : selectedOrder ? (
        <Fragment>
          <h3>
            <ShoppingCartIcon />
            Order# {selectedOrder.orderId}
          </h3>
          <h3>
            <LocationOnIcon />
            {selectedOrder.address.addressLineOne}
          </h3>
          <h3>
            <WatchLaterIcon />{" "}
            {selectedOrder.eta && convertToDateAndTime(selectedOrder.eta)}
          </h3>
        </Fragment>
      ) : (
        <Fragment>
          <h3>#{orderInfo}</h3>
        </Fragment>
      )}
    </div>
  );
};

export default WindowInfoComponent;
